"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsLoading = exports.setContentModal = exports.setMessageModal = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    isLoading: false,
    isDrawerOpen: false,
    drawerPageName: "login",
    messageModal: null,
    contentModal: null,
};
var slice = (0, toolkit_1.createSlice)({
    name: "main",
    initialState: initialState,
    reducers: {
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setMessageModal: function (state, action) {
            var _a;
            state.messageModal = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setContentModal: function (state, action) {
            var _a;
            state.contentModal = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
    },
});
exports.setMessageModal = (_a = slice.actions, _a.setMessageModal), exports.setContentModal = _a.setContentModal, exports.setIsLoading = _a.setIsLoading;
exports.default = slice.reducer;

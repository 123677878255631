"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchDebounce = void 0;
var react_1 = require("react");
function useSearchDebounce(delay) {
    if (delay === void 0) { delay = 750; }
    var _a = (0, react_1.useState)(null), search = _a[0], setSearch = _a[1];
    var _b = (0, react_1.useState)(null), searchQuery = _b[0], setSearchQuery = _b[1];
    (0, react_1.useEffect)(function () {
        var delayFn = setTimeout(function () { return setSearch(searchQuery); }, delay);
        return function () { return clearTimeout(delayFn); };
    }, [searchQuery, delay]);
    return [search, setSearchQuery];
}
exports.useSearchDebounce = useSearchDebounce;

"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCompanyInformation = exports.setCompanyDetails = exports.setOfferings = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var _devMode = process.env.NODE_ENV !== "production";
var initialState = {
    offerings: {
        experience: [],
        industry: [],
        offering: [],
        product_type: [],
    },
    companyDetails: {
        businessName: _devMode ? "DoshX" : undefined,
        businessRegisteredName: _devMode ? "Modus Nuggets" : undefined,
        businessRegisteredNumber: _devMode ? "2010/13/12345" : undefined,
        vatNumber: undefined,
        address: _devMode
            ? {
                fullAddress: "19 Fisant Avenue, Fourways, Sandton, South Africa",
                coordinates: {
                    lat: "-26.0247697",
                    lng: "28.0051823",
                },
            }
            : undefined,
        isLoading: false,
    },
    companyInformation: {
        industry: undefined,
        businessType: undefined,
        sellingProducts: undefined,
        isLoading: false,
    },
};
var slice = (0, toolkit_1.createSlice)({
    name: "entry",
    initialState: initialState,
    reducers: {
        setOfferings: function (state, action) {
            state.offerings = action.payload;
        },
        setCompanyDetails: function (state, action) {
            state.companyDetails = action.payload;
        },
        setCompanyInformation: function (state, action) {
            state.companyInformation = action.payload;
        },
    },
});
exports.setOfferings = (_a = slice.actions, _a.setOfferings), exports.setCompanyDetails = _a.setCompanyDetails, exports.setCompanyInformation = _a.setCompanyInformation;
exports.default = slice.reducer;

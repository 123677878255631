"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyDetailsPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var entry_1 = require("../../../../../reducers/slices/entry");
var registration_1 = require("../../../../../reducers/slices/registration");
var CompanyDetailsPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var entryTheme = (0, react_redux_1.useSelector)(function (state) { return state.entryState; }).entryTheme;
    var companyDetails = (0, react_redux_1.useSelector)(function (state) { return state.registrationState; }).companyDetails;
    var _a = (0, react_1.useState)(""), nameMessage = _a[0], setNameMessage = _a[1];
    var _b = (0, react_1.useState)(""), registeredNameMessage = _b[0], setRegisteredNameMessage = _b[1];
    var _c = (0, react_1.useState)(""), registeredNumberMessage = _c[0], setRegisteredNumberMessage = _c[1];
    var _d = (0, react_1.useState)(""), vatNumberMessage = _d[0], setVatNumberMessage = _d[1];
    var _e = (0, react_1.useState)(""), addressMessage = _e[0], setAddressMessage = _e[1];
    (0, react_1.useEffect)(function () {
        //
    }, []);
    var onControlChanged = function (name, value) {
        var _a;
        dispatch((0, registration_1.setCompanyDetails)(__assign(__assign({}, companyDetails), (_a = {}, _a[name] = value, _a))));
    };
    var validate = function () {
        var messageCount = 0;
        setNameMessage("");
        setRegisteredNameMessage("");
        setRegisteredNumberMessage("");
        // setVatNumberMessage("");
        setAddressMessage("");
        if (!companyDetails.businessName) {
            messageCount++;
            setNameMessage("Business name is required.");
        }
        if (!companyDetails.businessRegisteredName) {
            messageCount++;
            setRegisteredNameMessage("Registered name is required.");
        }
        if (!companyDetails.businessRegisteredNumber) {
            messageCount++;
            setRegisteredNumberMessage("Registered number is required.");
        }
        // if (!companyDetails.vatNumber) {
        //   messageCount++;
        //   setVatNumberMessage("Vat Number is required.");
        // }
        if (!companyDetails.address) {
            messageCount++;
            setAddressMessage("Address is required.");
        }
        // if (!user.password) {
        //   messageCount++;
        //   setAddressMessage("Password is required.");
        // }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "w-full lg:w-[700px] pt-8" },
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "2xlarge", label: "That's one small step for a business, one giant leap for mankind.", bold: true }),
        react_1.default.createElement("div", { className: "w-10" },
            react_1.default.createElement(doshx_controls_web_1.DividerControl, { orientation: "horizontal", lineColor: "bg-black", addPadding: true })),
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "medium", label: "Let's get started.", bold: true }),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "xsmall", label: "What is your business name?" }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Business Name", required: true, value: companyDetails.businessName, errorText: nameMessage, errorTextColor: "text-error", onChange: function (v) { return onControlChanged("businessName", v); } })),
        react_1.default.createElement("div", { className: "flex flex-col gap-y-4 pt-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "medium", label: "Tell us more about your business.", bold: true }),
            react_1.default.createElement("div", { className: "flex flex-col gap-y-4" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Registered Business Name", required: true, value: companyDetails.businessRegisteredName, errorText: registeredNameMessage, errorTextColor: "text-error", onChange: function (v) { return onControlChanged("businessRegisteredName", v); } })),
            react_1.default.createElement("div", { className: "flex flex-col gap-y-4" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Registered Business Number", required: true, value: companyDetails.businessRegisteredNumber, errorText: registeredNumberMessage, errorTextColor: "text-error", onChange: function (v) { return onControlChanged("businessRegisteredNumber", v); } })),
            react_1.default.createElement("div", { className: "flex flex-col gap-y-4" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "VAT Number", value: companyDetails === null || companyDetails === void 0 ? void 0 : companyDetails.vatNumber, errorText: vatNumberMessage, errorTextColor: "text-error", onChange: function (v) { return onControlChanged("vatNumber", v); } })),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.AddressSearchControl, { letsTradeAPIUrl: process.env.LETS_TRADE_CLIENT_URL, value: companyDetails === null || companyDetails === void 0 ? void 0 : companyDetails.address, label: "Business Address", size: "medium", textColor: "text-black", onSelectionChange: function (v) {
                        console.log(v);
                        onControlChanged("address", v);
                    }, required: true, errorText: addressMessage, errorTextColor: "text-error" }))),
        react_1.default.createElement("div", { className: "flex flex-row gap-4  pt-8" },
            react_1.default.createElement("div", { className: "w-[200px]" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Next", backgroundColor: entryTheme.config.colors.buttons.bgPrimary, loading: companyDetails === null || companyDetails === void 0 ? void 0 : companyDetails.isLoading, onClick: function () {
                        if (validate()) {
                            // dispatch(sign_up_thunk());
                            dispatch((0, entry_1.setCurrentStep)(2));
                        }
                    } })),
            react_1.default.createElement("div", { className: "w-[200px]" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Back", backgroundColor: "bg-black", loading: companyDetails === null || companyDetails === void 0 ? void 0 : companyDetails.isLoading, onClick: function () {
                        //   if (validate()) {
                        // dispatch(sign_up_thunk());
                        dispatch((0, entry_1.setCurrentStep)(0));
                        //   }
                    } })))));
};
exports.CompanyDetailsPage = CompanyDetailsPage;

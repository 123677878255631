"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDate = void 0;
function formatDate(dateString) {
    var date = new Date(dateString);
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    return "".concat(month.toString().padStart(2, "0"), "-").concat(day.toString().padStart(2, "0"), "-").concat(year.toString());
}
exports.formatDate = formatDate;

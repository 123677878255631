"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clientSubsciptionStatusSelector = exports.isAdminSelector = exports.isManagerSelector = exports.getUserRoleSelector = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var constants_1 = require("../constants/constants");
var getClientState = function (state) { return state.clientState; };
var getUserState = function (state) { return state.userState; };
exports.getUserRoleSelector = (0, toolkit_1.createSelector)([getClientState, getUserState], function (_a, _b) {
    var _c;
    var client = _a.client;
    var user = _b.user;
    var manager_of = ((_c = client === null || client === void 0 ? void 0 : client.users.find(function (clientUser) { return clientUser.user_id === user._id; })) !== null && _c !== void 0 ? _c : {}).manager_of;
    var role = user.role;
    return { role: role, manager_of: manager_of };
});
exports.isManagerSelector = (0, toolkit_1.createSelector)(exports.getUserRoleSelector, function (_a) {
    var role = _a.role, manager_of = _a.manager_of;
    var isManager = role === constants_1.BRANCH_MANAGER || role === constants_1.AREA_MANAGER;
    var managedBanches = manager_of;
    return { isManager: isManager, managedBanches: managedBanches };
});
exports.isAdminSelector = (0, toolkit_1.createSelector)(exports.getUserRoleSelector, function (_a) {
    var role = _a.role;
    return role === constants_1.ADMIN;
});
exports.clientSubsciptionStatusSelector = (0, toolkit_1.createSelector)(getClientState, function (_a) {
    var clientSubscription = _a.clientSubscription;
    var _b = clientSubscription !== null && clientSubscription !== void 0 ? clientSubscription : {}, name = _b.name, status = _b.status;
    var isActive = status === "ACTIVE";
    var isUnsubscribed = !(clientSubscription === null || clientSubscription === void 0 ? void 0 : clientSubscription.name);
    var isFreemiumPlan = isActive && name === constants_1.SubscriptionPlans.FREEMIUM;
    var isBusinessPlan = isActive && name === constants_1.SubscriptionPlans.BUSINESS;
    var isGrowthPlan = isActive && name === constants_1.SubscriptionPlans.GROWTH;
    var isStarterPlan = isActive && name === constants_1.SubscriptionPlans.STARTER;
    return { isUnsubscribed: isUnsubscribed, isFreemiumPlan: isFreemiumPlan, isBusinessPlan: isBusinessPlan, isGrowthPlan: isGrowthPlan, isStarterPlan: isStarterPlan };
});

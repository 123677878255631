"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSkipFirstRender = void 0;
var react_1 = require("react");
function useSkipFirstRender(callback, dependencies) {
    var isFirstRender = (0, react_1.useRef)(true);
    (0, react_1.useEffect)(function () {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        callback();
    }, dependencies);
}
exports.useSkipFirstRender = useSkipFirstRender;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersTablePage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var pages_1 = require("../../../../constants/pages");
var routes_1 = require("../../../../constants/routes");
var client_1 = require("../../../../reducers/slices/client/client");
var get_client_user_list_thunk_1 = require("../../../../reducers/slices/client/thunks/get_client_user_list_thunk");
var console_1 = require("../../../../reducers/slices/console");
var constants_1 = require("./constants");
var useSearchDebounce_1 = require("../../../../hooks/useSearchDebounce");
var UsersTablePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(1000), searchString = _a[0], setSearchString = _a[1];
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }), clientUsers = _b.clientUsers, isLoading = _b.isLoading, pagination = _b.pagination;
    var _c = (0, react_1.useState)(pages_1.initialSortValues), clientUsersSorter = _c[0], setSorter = _c[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var query = __assign({}, (searchString && { search_string: searchString, showLoader: false }));
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Users"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (searchString)
            _getClientUsers();
    }, [searchString]);
    (0, react_1.useEffect)(function () {
        if (user === null || user === void 0 ? void 0 : user._id) {
            _getClientUsers(__assign({}, pagination));
        }
    }, [user]);
    var _getClientUsers = function (params) {
        if (params === void 0) { params = {}; }
        return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, dispatch((0, get_client_user_list_thunk_1.get_client_user_list_thunk)(__assign(__assign(__assign({}, pagination), query), params)))];
        }); });
    };
    var _onPageChange = function (page) {
        return _getClientUsers({
            page: page,
        });
    };
    var _onRowsPerPageChange = function (size) {
        return _getClientUsers({
            size: size,
        });
    };
    var rowActions = [
        {
            label: "View",
            onClick: function (user) {
                dispatch((0, client_1.setSelectedClientUser)(__assign(__assign({}, constants_1.initialUserUpdateValues), user)));
                return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.USERS_ROUTE).concat(routes_1.USER_ROUTE, "/").concat(user.user_id));
            },
        },
    ];
    var onSortTable = function (sorter) {
        setSorter(sorter);
        _getClientUsers({ sort_by: sorter.key, order_by: sorter.direction });
    };
    return (react_1.default.createElement("div", { className: "w-full flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex justify-end gap-4" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Add User", endIcon: pro_light_svg_icons_1.faPlus, size: "small", backgroundColor: "bg-success", textColor: "text-white", onClick: function () {
                    dispatch((0, client_1.setSelectedClientUser)(constants_1.initialUserValues));
                    return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.USERS_ROUTE).concat(routes_1.USER_ROUTE));
                } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", backgroundColor: "bg-white", label: "Search", endIcon: pro_light_svg_icons_1.faSearch, size: "small", onChange: setSearchString })),
        isLoading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge" })) : (react_1.default.createElement(doshx_controls_web_1.TableControl, { sort: {
                value: clientUsersSorter,
                onChange: onSortTable,
            }, columns: constants_1.tableColumns, rowActions: rowActions, data: clientUsers, hasShadow: true, pagination: {
                currentPage: pagination.page,
                rowsPerPage: pagination.size,
                totalRecords: pagination.countTotal,
                onPageChange: _onPageChange,
                onRowsPerPageChange: _onRowsPerPageChange,
            }, colors: pages_1.tableColorConfig }))));
};
exports.UsersTablePage = UsersTablePage;

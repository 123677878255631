"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailNotification = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var constants_1 = require("../../../../constants/constants");
var validateForm_1 = require("../../../../helpers/validateForm/validateForm");
var client_1 = require("../../../../reducers/slices/client/client");
var get_client_thunk_1 = require("../../../../reducers/slices/client/thunks/get_client_thunk");
var main_1 = require("../../../../reducers/slices/main");
var add_client_integration_1 = require("../../../../api/calls/client/add_client_integration");
var constants_2 = require("./constants");
var pages_1 = require("../../../../constants/pages");
var NOTIFICATION = "NOTIFICATION";
var EmailNotification = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }), selectedClientIntegration = _a.selectedClientIntegration, clientIntegrations = _a.clientIntegrations;
    var _b = (0, react_1.useState)(false), isEditing = _b[0], setIsEditing = _b[1];
    var data = selectedClientIntegration.data, _id = selectedClientIntegration._id;
    var validationConfig = { enabled: { required: true }, returns_email_address: { dependOn: { key: "enabled", value: true } } };
    var _c = (0, validateForm_1.validateForm)(data, { validationConfig: validationConfig }), errors = _c.errors, isValid = _c.isValid;
    var enabledOptions = [
        { label: "No", value: "false" },
        { label: "Yes", value: "true" },
    ];
    var _setFormData = function (value) {
        dispatch((0, client_1.setSelectedClientIntegration)(__assign(__assign({}, selectedClientIntegration), { data: __assign(__assign({}, data), value) })));
    };
    (0, react_1.useEffect)(function () {
        if (!(client === null || client === void 0 ? void 0 : client._id)) {
            dispatch((0, get_client_thunk_1.get_client_thunk)());
        }
    }, [client]);
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    (0, react_1.useEffect)(function () {
        var existingIntegrationValues = clientIntegrations.find(function (int) { return int.type === NOTIFICATION; });
        dispatch((0, client_1.setSelectedClientIntegration)(existingIntegrationValues !== null && existingIntegrationValues !== void 0 ? existingIntegrationValues : constants_2.initialNotificationValues));
    }, []);
    var _onUpdateNotificationConfig = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isEditing) {
                        return [2 /*return*/, setIsEditing(!isEditing)];
                    }
                    showLoader();
                    return [4 /*yield*/, (0, add_client_integration_1.add_client_integration)({ type: NOTIFICATION, data: data })];
                case 1:
                    response = _a.sent();
                    if (response === null || response === void 0 ? void 0 : response.success) {
                        dispatch((0, get_client_thunk_1.get_client_thunk)());
                        dispatch((0, main_1.setContentModal)(null));
                        setIsEditing(!isEditing);
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
                    return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "flex flex-auto bg-white", borderColor: "border-transparent" },
        react_1.default.createElement("div", { className: "flex flex-col gap-4 flex-auto" },
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row" },
                react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-4" },
                    react_1.default.createElement("div", { className: "flex gap-4 bg-white" },
                        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { required: isEditing, title: "Email Notifications", colors: __assign(__assign({}, pages_1.radioButtonColorConfig), { titleColor: isEditing ? "text-black" : "text-disabled", radio: __assign(__assign({}, pages_1.radioButtonColorConfig.radio), { textColor: isEditing ? "text-black" : "text-disabled", iconColor: isEditing ? "text-black" : "text-disabled" }) }), options: enabledOptions, selectedValue: (data === null || data === void 0 ? void 0 : data.enabled) ? "true" : "false", onChange: function (value) {
                                    if (!isEditing)
                                        return;
                                    _setFormData({ enabled: value === "true" });
                                } }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Send returns notifications to below email:", textColor: isEditing && (data === null || data === void 0 ? void 0 : data.enabled) ? "text-black" : "text-disabled", size: "small" }),
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email Address", required: isEditing && (data === null || data === void 0 ? void 0 : data.enabled), disabled: !isEditing && (data === null || data === void 0 ? void 0 : data.enabled), value: data === null || data === void 0 ? void 0 : data.returns_email_address, errorText: errors === null || errors === void 0 ? void 0 : errors.returns_email_address, onChange: function (returns_email_address) { return _setFormData({ returns_email_address: returns_email_address }); }, backgroundColor: "bg-gray", textColor: isEditing && (data === null || data === void 0 ? void 0 : data.enabled) ? "text-black" : "text-disabled", labelColor: isEditing && (data === null || data === void 0 ? void 0 : data.enabled) ? "text-black" : "text-disabled", borderColor: isEditing && (data === null || data === void 0 ? void 0 : data.enabled) ? "border-gray-500" : "border-disabled", lines: 1, size: "small" }))),
                    react_1.default.createElement("div", { className: "flex flex-row items-center gap-4" },
                        react_1.default.createElement("div", { className: "w-[100px]" },
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: isEditing ? "Done" : "Edit", size: "medium", enabled: !isEditing || isValid, onClick: _onUpdateNotificationConfig }))))))));
};
exports.EmailNotification = EmailNotification;

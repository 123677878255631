"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDealCategoryProductsPagination = exports.setDealCategoryProducts = exports.setClientTags = exports.setExcludedProductsPagination = exports.setExcludedProducts = exports.setVoucherProductsPagination = exports.setVoucherProducts = exports.setClientFilteredComboProductsPagination = exports.setClientFilteredProductsPagination = exports.setDealProductsPagination = exports.setClientFilteredComboProducts = exports.setClientFilteredProducts = exports.setSelectedCategory = exports.setSelectedProducts = exports.setDealProducts = exports.setProductVariances = exports.setProductAddOnsPagination = exports.setProductAddOns = exports.setTablePagination = exports.setLoading = exports.setLetstradeProductsLoaded = exports.setShopifyProductsLoaded = exports.deleteProducts = exports.editProduct = exports.updateProducts = exports.setProducts = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    products: [],
    productAddOns: [],
    dealProducts: [],
    clientFilteredProducts: [],
    clientFilteredComboProducts: [],
    voucherProducts: [],
    productVariances: [],
    excludedProducts: [],
    clientTags: [],
    dealCategoryProducts: [],
    selectedProducts: [],
    selectedCategory: [],
    loading: false,
    shopifyProductsLoaded: false,
    letstradeProductsLoaded: false,
    pagination: pages_1.initialPaginationValues,
    productAddOnsPagination: pages_1.initialPaginationValues,
    dealProductsPagination: pages_1.dealsInitialPaginationValues,
    clientFilteredProductsPagination: pages_1.filteredClientdealProductsInitialPaginationValues,
    clientFilteredComboProductsPagination: pages_1.comboInitialPaginationValues,
    voucherProductsPagination: pages_1.initialPaginationValues,
    excludedProductsPagination: pages_1.initialPaginationValues,
    dealCategoryProductsPagination: pages_1.dealsCategoriesInitialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "product",
    initialState: initialState,
    reducers: {
        setProducts: function (state, action) {
            state.products = action.payload;
        },
        setExcludedProducts: function (state, action) {
            state.excludedProducts = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
        updateProducts: function (state, action) {
            state.products.push(action.payload);
            // state.loading = false;
        },
        editProduct: function (state, action) {
            state.products = state.products.map(function (x) {
                if (x._id === action.payload._id) {
                    return action.payload;
                }
                return x;
            });
        },
        deleteProducts: function (state, action) {
            state.products.filter(function (x) { return x._id !== action.payload; });
        },
        setShopifyProductsLoaded: function (state, action) {
            state.shopifyProductsLoaded = action.payload;
        },
        setLetstradeProductsLoaded: function (state, action) {
            state.loading = action.payload;
        },
        setLoading: function (state, action) {
            state.letstradeProductsLoaded = action.payload;
        },
        setProductAddOns: function (state, action) {
            state.productAddOns = action.payload;
        },
        setDealProducts: function (state, action) {
            state.dealProducts = action.payload;
        },
        setSelectedProducts: function (state, action) {
            state.selectedProducts = action.payload;
        },
        setSelectedCategory: function (state, action) {
            state.selectedCategory = action.payload;
        },
        setDealCategoryProducts: function (state, action) {
            state.dealCategoryProducts = action.payload;
        },
        setDealCategoryProductsPagination: function (state, action) {
            state.dealCategoryProductsPagination = action.payload;
        },
        setClientFilteredProducts: function (state, action) {
            state.clientFilteredProducts = action.payload;
        },
        setClientFilteredComboProducts: function (state, action) {
            state.clientFilteredComboProducts = action.payload;
        },
        setVoucherProducts: function (state, action) {
            state.voucherProducts = action.payload;
        },
        setProductAddOnsPagination: function (state, action) {
            state.productAddOnsPagination = action.payload;
        },
        setDealProductsPagination: function (state, action) {
            state.dealProductsPagination = action.payload;
        },
        setClientFilteredProductsPagination: function (state, action) {
            state.clientFilteredProductsPagination = action.payload;
        },
        setClientFilteredComboProductsPagination: function (state, action) {
            state.clientFilteredComboProductsPagination = action.payload;
        },
        setVoucherProductsPagination: function (state, action) {
            state.voucherProductsPagination = action.payload;
        },
        setExcludedProductsPagination: function (state, action) {
            state.excludedProductsPagination = action.payload;
        },
        setProductVariances: function (state, action) {
            state.productVariances = action.payload;
        },
        setClientTags: function (state, action) {
            state.clientTags = action.payload;
        },
    },
});
exports.setProducts = (_a = slice.actions, _a.setProducts), exports.updateProducts = _a.updateProducts, exports.editProduct = _a.editProduct, exports.deleteProducts = _a.deleteProducts, exports.setShopifyProductsLoaded = _a.setShopifyProductsLoaded, exports.setLetstradeProductsLoaded = _a.setLetstradeProductsLoaded, exports.setLoading = _a.setLoading, exports.setTablePagination = _a.setTablePagination, exports.setProductAddOns = _a.setProductAddOns, exports.setProductAddOnsPagination = _a.setProductAddOnsPagination, exports.setProductVariances = _a.setProductVariances, exports.setDealProducts = _a.setDealProducts, exports.setSelectedProducts = _a.setSelectedProducts, exports.setSelectedCategory = _a.setSelectedCategory, exports.setClientFilteredProducts = _a.setClientFilteredProducts, exports.setClientFilteredComboProducts = _a.setClientFilteredComboProducts, exports.setDealProductsPagination = _a.setDealProductsPagination, exports.setClientFilteredProductsPagination = _a.setClientFilteredProductsPagination, exports.setClientFilteredComboProductsPagination = _a.setClientFilteredComboProductsPagination, exports.setVoucherProducts = _a.setVoucherProducts, exports.setVoucherProductsPagination = _a.setVoucherProductsPagination, exports.setExcludedProducts = _a.setExcludedProducts, exports.setExcludedProductsPagination = _a.setExcludedProductsPagination, exports.setClientTags = _a.setClientTags, exports.setDealCategoryProducts = _a.setDealCategoryProducts, exports.setDealCategoryProductsPagination = _a.setDealCategoryProductsPagination;
exports.default = slice.reducer;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectToQueryString = void 0;
function objectToQueryString(obj, prependQuestion) {
    if (prependQuestion === void 0) { prependQuestion = true; }
    if (!obj)
        return "";
    var queryString = Object.keys(obj)
        .map(function (key) {
        if (typeof obj[key] === "boolean") {
            return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key].toString());
        }
        if (obj[key]) {
            return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
        }
        return "";
    })
        .filter(Boolean)
        .join("&");
    if (queryString && prependQuestion)
        queryString = "?".concat(queryString);
    return queryString;
}
exports.objectToQueryString = objectToQueryString;

"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setErrors = exports.setPagination = exports.setIsEdited = exports.setIsLoading = exports.setChallenges = exports.clearState = exports.setSelectedChallenge = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    selectedChallenge: {
        title: "",
        event_type: "",
        scope: {
            type: "",
            value: "",
        },
        trigger: {
            consecutive_count: false,
            trigger_quantity: 0,
        },
        schedule: {
            start_date: null,
            end_date: null,
        },
        status: "draft",
        rewards: [
            {
                type: "",
                value: "",
                currency_type: "",
                currency: "",
            },
        ],
        granted_quantity: 0,
        completed_quantity: 0,
        visible: false,
        item_id: "",
    },
    challenges: [],
    pagination: pages_1.initialPaginationValues,
    isLoading: false,
    isEdited: false,
    errors: {
        title: "",
        event_type: "",
        scope: {},
        trigger: {},
        schedule: {},
        rewards: [],
    },
};
var slice = (0, toolkit_1.createSlice)({
    name: "challengeState",
    initialState: initialState,
    reducers: {
        setSelectedChallenge: function (state, action) {
            state.selectedChallenge = action.payload;
        },
        clearState: function (state) {
            state.selectedChallenge = initialState.selectedChallenge;
            state.challenges = initialState.challenges;
            state.pagination = initialState.pagination;
            state.isLoading = initialState.isLoading;
            state.isEdited = initialState.isEdited;
            state.errors = initialState.errors;
        },
        setChallenges: function (state, action) {
            state.challenges = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setIsEdited: function (state, action) {
            state.isEdited = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setErrors: function (state, action) {
            state.errors = action.payload;
        },
    },
});
exports.setSelectedChallenge = (_a = slice.actions, _a.setSelectedChallenge), exports.clearState = _a.clearState, exports.setChallenges = _a.setChallenges, exports.setIsLoading = _a.setIsLoading, exports.setIsEdited = _a.setIsEdited, exports.setPagination = _a.setPagination, exports.setErrors = _a.setErrors;
exports.default = slice.reducer;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validationConfig = exports.statusOptions = exports.accountTypeOptions = exports.initialUserUpdateValues = exports.initialUserValues = exports.initialPermissionsValues = exports.featurePermissions = exports.userBranchTableColumns = exports.tableColumns = void 0;
var user_1 = require("../../../../reducers/slices/user/user");
var constants_1 = require("../../constants");
exports.tableColumns = [
    {
        displayKey: "name",
        label: "Name",
    },
    {
        displayKey: "surname",
        label: "Surname",
    },
    {
        displayKey: "cell_number",
        label: "Phone Number",
    },
    {
        displayKey: "email",
        label: "Email",
    },
    {
        displayKey: "role",
        label: "Account Type",
    },
    {
        displayKey: "status",
        label: "Status",
        formatter: function (x) {
            if (x) {
                return (0, constants_1.TABLE_STATUSES)(x.status);
            }
        },
    },
];
exports.userBranchTableColumns = [
    {
        displayKey: "branch_number",
        label: "Branch Number",
    },
    {
        displayKey: "branch_name",
        label: "Branch Name",
    },
    {
        displayKey: "phone",
        label: "Phone",
    },
    {
        displayKey: "email",
        label: "Email",
    },
    {
        displayKey: "total_revenue",
        label: "Total Revenue",
    },
    {
        displayKey: "average_monthly_revenue",
        label: "Average Monthly Revenue",
    },
    {
        displayKey: "deliveries",
        label: "Deliveries",
    },
    {
        displayKey: "status",
        label: "Status",
    },
];
exports.featurePermissions = {
    BADGES: "Badges",
    CHALLENGES: "Challenges",
    REWARD_TRACKS: "Reward Tracks",
    ECONOMICS: "Economics",
    WALLET: "Wallet",
    CASHBACK: "Cashback",
    XP_POINTS: "XP Points",
    CUSTOMERS: "Customers",
};
var initialPermissionsValues = function (accountType) {
    if (accountType === void 0) { accountType = "ADMIN"; }
    var isAdmin = accountType === "ADMIN";
    var isBranchManager = accountType === "BRANCH_MANAGER";
    var isManager = accountType === "AREA_MANAGER" || isBranchManager;
    var featureConfig = {
        read: isAdmin,
        create: isAdmin,
        update: isAdmin,
        delete: isAdmin,
    };
    var permissions = {
        BADGES: featureConfig,
        CHALLENGES: featureConfig,
        REWARD_TRACKS: featureConfig,
        ECONOMICS: featureConfig,
        WALLET: featureConfig,
        CASHBACK: featureConfig,
        XP_POINTS: featureConfig,
        CUSTOMERS: featureConfig,
    };
    return permissions;
};
exports.initialPermissionsValues = initialPermissionsValues;
exports.initialUserValues = {
    name: user_1.devMode ? "John" : "",
    surname: user_1.devMode ? "Doe" : "",
    password: user_1.devMode ? "12345" : "",
    user_name: user_1.devMode ? "John" : "",
    cell_number: user_1.devMode ? "123-456-7890" : "",
    email: user_1.devMode ? "johndoe@example.com" : "",
    role: "ADMIN",
    status: user_1.devMode ? "ACTIVE" : "",
    manager_of: [],
    user_permissions: (0, exports.initialPermissionsValues)(),
    get_communication: false,
    agrees_terms_and_conditions: true,
};
// There's probably a better way to approach this, but we
// need to somehow make provisions for users that were previously
// created without fields like user_permissions
exports.initialUserUpdateValues = {
    status: "ACTIVE",
    manager_of: [],
    user_permissions: (0, exports.initialPermissionsValues)(),
};
exports.accountTypeOptions = [
    { label: "Admin", value: "ADMIN" },
    { label: "Area Manager", value: "AREA_MANAGER" },
    { label: "Branch Manager", value: "BRANCH_MANAGER" },
];
exports.statusOptions = [
    { label: "Active", value: "ACTIVE" },
    { label: "Inactive", value: "INACTIVE" },
];
exports.validationConfig = {
    manager_of: { dependOn: { key: "role", value: ["BRANCH_MANAGER", "AREA_MANAGER"] } },
};

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractValidationValues = exports.validateForm = exports._devMode = void 0;
exports._devMode = process.env.NODE_ENV !== "production";
function validateForm(input, config) {
    if (config === void 0) { config = {
        enabled: true,
        validationConfig: {},
        initialValues: undefined,
    }; }
    var enabled = config.enabled, validationConfig = config.validationConfig, initialValues = config.initialValues;
    var errors = {};
    if (enabled !== undefined && !enabled)
        return { errors: errors, isValid: false };
    Object.entries(__assign(__assign({}, extractValidationValues(initialValues !== null && initialValues !== void 0 ? initialValues : input)), validationConfig)).forEach(function (_a) {
        var _b;
        var key = _a[0], value = _a[1];
        var _c = (_b = value) !== null && _b !== void 0 ? _b : {}, name = _c.name, required = _c.required, type = _c.type, charLength = _c.charLength, dependOn = _c.dependOn, isEqualTo = _c.isEqualTo;
        if (value)
            if (dependOn) {
                var dependOnKey = dependOn.key, dependOnValue = dependOn.value;
                var charArray = Array.isArray(dependOnValue) ? __spreadArray([], dependOnValue, true) : [dependOnValue];
                if (input && charArray.includes(input[dependOnKey]) && (!input[key] || isEmptyValue(input[key]))) {
                    errors[key] = "".concat(key.replace(/_/g, " "), " is required");
                }
            }
            else if (isEqualTo) {
                var isEqualToKey = isEqualTo.key, isEqualToValue = isEqualTo.value;
                var charArray = Array.isArray(isEqualToValue) ? __spreadArray([], isEqualToValue, true) : [isEqualToValue];
                if (input && charArray.includes(input[isEqualToKey]) && input[isEqualToKey] !== input[key]) {
                    errors[key] = "".concat(isEqualToKey.replace(/_/g, " "), "(s) do not match");
                }
            }
            else if (required) {
                if ((input && isEmptyValue(input[key])) || input === null) {
                    errors[key] = "".concat(name || key.replace(/_/g, " "), " is required");
                }
                else if ((key === "email" || type === "emailAddress") && !validateEmail(input[key])) {
                    errors[key] = "".concat(name || key.replace(/_/g, " "), " must be a valid email address");
                }
                else if ((key === "phone" || key == "contact_number" || type === "phoneNumber") && !validatePhone(input[key])) {
                    errors[key] = "".concat(name || key.replace(/_/g, " "), " must be a 10-digit phone number");
                }
                else if (charLength && input[key].length > charLength) {
                    errors[key] = "".concat(name || key.replace(/_/g, " "), " must not be more than ").concat(charLength);
                }
                else if (type === "number") {
                    errors[key] = "".concat(name || key.replace(/_/g, " "), " must be a number");
                }
                else if (type === "url" && !validateUrl(input[key])) {
                    errors[key] = "".concat(name || key.replace(/_/g, " "), " must not include http:// or https://");
                }
                // --- add conditions for "date" | "text"
            }
            else
                return;
    });
    var isValid = Object.keys(errors).length === 0;
    return { errors: errors, isValid: isValid };
}
exports.validateForm = validateForm;
function isEmptyValue(value) {
    var isObject = typeof value === "object";
    return value === "" || value === null || (Array.isArray(value) && value.length === 0) || (isObject && Object.keys(value).length === 0);
}
function validateUrl(url) {
    // url validation regex
    var regex = /^((https?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!$&'()*+,;=]*)$/;
    return regex.test(url);
}
function validateEmail(email) {
    // email validation regex
    var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
}
function validatePhone(phone) {
    // phone number validation regex
    var regex = /^\d{10}$/;
    return regex.test(phone);
}
function extractValidationValues(initialData) {
    return Object.keys(initialData !== null && initialData !== void 0 ? initialData : {}).reduce(function (result, key) {
        var _a;
        return __assign(__assign({}, result), (_a = {}, _a[key] = { required: true }, _a));
    }, {});
}
exports.extractValidationValues = extractValidationValues;

"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPaymentEngineToken = exports.setMediaUploadToken = exports.setClientToken = exports.setUserToken = exports.setIsLoading = exports.setUser = exports.devMode = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
exports.devMode = process.env.NODE_ENV !== "production";
var initialState = {
    user: {
        username: exports.devMode ? "Fred" : "",
        email: exports.devMode ? "fred@doshex.com" : "",
        cell_number: exports.devMode ? "0837983429" : "",
        password: exports.devMode ? "0000" : "",
        confirm_password: exports.devMode ? "0000" : "",
        name: exports.devMode ? "Fred" : "",
        surname: exports.devMode ? "Moller" : "",
        reset_password_otp: undefined,
        remember_me: true,
        birthday: exports.devMode ? "11/25/1991" : undefined,
        client_id: "",
        payment_engine_id: "",
        agrees_terms_and_conditions: exports.devMode && true,
    },
    token: "",
    client_token: "",
    media_upload_token: "",
    payment_engine_token: "",
    isLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "user",
    initialState: initialState,
    reducers: {
        setUser: function (state, action) {
            state.user = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setUserToken: function (state, action) {
            state.token = action.payload;
        },
        setClientToken: function (state, action) {
            state.client_token = action.payload;
        },
        setMediaUploadToken: function (state, action) {
            state.media_upload_token = action.payload;
        },
        setPaymentEngineToken: function (state, action) {
            state.payment_engine_token = action.payload;
        },
    },
});
exports.setUser = (_a = slice.actions, _a.setUser), exports.setIsLoading = _a.setIsLoading, exports.setUserToken = _a.setUserToken, exports.setClientToken = _a.setClientToken, exports.setMediaUploadToken = _a.setMediaUploadToken, exports.setPaymentEngineToken = _a.setPaymentEngineToken;
exports.default = slice.reducer;

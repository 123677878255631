"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerLoyaltyDetails = void 0;
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var update_loyalty_1 = require("../../../../../api/calls/loyalty/update/update_loyalty");
var constants_1 = require("../../../../../constants/constants");
var validateForm_1 = require("../../../../../helpers/validateForm/validateForm");
var loyalty_1 = require("../../../../../reducers/slices/loyalty/loyalty");
var get_loyalty_thunk_1 = require("../../../../../reducers/slices/loyalty/thunks/get/get_loyalty_thunk");
var main_1 = require("../../../../../reducers/slices/main");
var constants_2 = require("../constants");
var CustomerLoyaltyDetails = function (_a) {
    var _b, _c, _d, _e;
    var customerId = _a.customerId;
    var dispatch = (0, react_redux_1.useDispatch)();
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var _f = (0, react_redux_1.useSelector)(function (state) { return state.loyaltyState; }), loyalty = _f.loyalty, selectedLoyalty = _f.selectedLoyalty;
    var _g = (0, react_1.useState)(false), editState = _g[0], setEditState = _g[1];
    var _h = (0, validateForm_1.validateForm)(selectedLoyalty), errors = _h.errors, isValid = _h.isValid;
    var _j = (0, validateForm_1.validateForm)(selectedLoyalty.card, { validationConfig: constants_2.cardValidationConfig }), cardErrors = _j.errors, cardIsValid = _j.isValid;
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_loyalty_thunk_1.get_loyalty_thunk)({ customer_id: customerId }));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        if (loyalty && (loyalty === null || loyalty === void 0 ? void 0 : loyalty._id)) {
            dispatch((0, loyalty_1.setSelectedLoyalty)(loyalty));
        }
    }, [loyalty]);
    var _setLoyalty = function (value) {
        dispatch((0, loyalty_1.setSelectedLoyalty)(__assign(__assign({}, selectedLoyalty), value)));
    };
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    var _updateLoyalty = function () { return __awaiter(void 0, void 0, void 0, function () {
        var card, member_date, payload, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(isValid && cardIsValid)) return [3 /*break*/, 2];
                    showLoader();
                    card = selectedLoyalty.card, member_date = selectedLoyalty.member_date, payload = __rest(selectedLoyalty, ["card", "member_date"]);
                    return [4 /*yield*/, (0, update_loyalty_1.update_loyalty)(__assign(__assign({}, payload), { member_card_number: selectedLoyalty.card.number, linked: selectedLoyalty.card.linked }))];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        dispatch((0, get_loyalty_thunk_1.get_loyalty_thunk)({ customer_id: customerId }));
                        setEditState(false);
                        dispatch((0, main_1.setContentModal)(null));
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement("div", { className: "flex flex-col" }, editState ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex items-center gap-2" },
            react_1.default.createElement("div", { className: "flex-1" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Update Loyalty", size: "small", textColor: "text-inputText", bold: true })),
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faCheck, size: "xsmall", backgroundColor: "bg-green-700", borderColor: "border-transparent", iconColor: "text-black", shape: "circle", onClick: _updateLoyalty })),
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faTimes, size: "xsmall", backgroundColor: "bg-red-700", borderColor: "border-transparent", iconColor: "text-white", shape: "circle", onClick: function () {
                        setEditState(false);
                    } }))),
        react_1.default.createElement("div", { className: "flex flex-col" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: selectedLoyalty === null || selectedLoyalty === void 0 ? void 0 : selectedLoyalty.loyalty_number, onChange: function (loyalty_number) {
                    _setLoyalty({ loyalty_number: loyalty_number });
                }, errorText: errors.loyalty_number, label: "Loyalty number", backgroundColor: "bg-gray", borderColor: "border-transaparent", size: "small" })),
        react_1.default.createElement("div", { className: "flex flex-col" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: (_b = selectedLoyalty === null || selectedLoyalty === void 0 ? void 0 : selectedLoyalty.card) === null || _b === void 0 ? void 0 : _b.number, onChange: function (number) {
                    var linked = (selectedLoyalty === null || selectedLoyalty === void 0 ? void 0 : selectedLoyalty.card).linked;
                    _setLoyalty({ card: { number: number, linked: linked } });
                }, errorText: cardErrors === null || cardErrors === void 0 ? void 0 : cardErrors.number, label: "Loyalty card number", backgroundColor: "bg-gray", borderColor: "border-transaparent", size: "small" })),
        react_1.default.createElement("div", { className: "flex flex-col" },
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Link to Physical Card", decorationColor: "decoration-borders", uncheckedColor: "text-textInput", checkedColor: "text-success", value: (_c = selectedLoyalty === null || selectedLoyalty === void 0 ? void 0 : selectedLoyalty.card) === null || _c === void 0 ? void 0 : _c.linked, errorText: cardErrors === null || cardErrors === void 0 ? void 0 : cardErrors.linked, size: "xsmall", textColor: "text-textInput", onChange: function (linked) {
                    var number = (selectedLoyalty === null || selectedLoyalty === void 0 ? void 0 : selectedLoyalty.card).number;
                    _setLoyalty({ card: { linked: linked, number: number } });
                } })),
        react_1.default.createElement("div", { className: "flex flex-col" },
            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Card Linked", size: "small", options: [
                    { value: "yes", label: "Yes" },
                    { value: "no", label: "No" },
                ], value: ((_d = selectedLoyalty === null || selectedLoyalty === void 0 ? void 0 : selectedLoyalty.card) === null || _d === void 0 ? void 0 : _d.linked) ? "yes" : "no", borderColor: "border-slate-400", textColor: "text-slate-400", labelColor: "text-slate-400", iconColor: "text-slate-400", onChange: function (value) {
                    _setLoyalty({});
                } })),
        react_1.default.createElement("div", { className: "flex flex-col" },
            react_1.default.createElement(doshx_controls_web_1.DatePickerControl, { label: "Date Activated", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: new Date(selectedLoyalty.member_date), onChange: function (value) {
                    _setLoyalty({ member_date: doshx_controls_web_1.Utilities.formatDateTime(value, "YYYY-MM-DD") });
                }, size: "small" })))) : (react_1.default.createElement("div", null,
        react_1.default.createElement("div", { className: "flex items-center mb-4" },
            react_1.default.createElement("div", { className: "flex-1" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Loyalty Details", size: "small", textColor: "text-inputText", bold: true, className: "leading-5" })),
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faPencil, size: "xsmall", backgroundColor: "bg-borders", borderColor: "border-transparent", iconColor: "text-black", shape: "circle", onClick: function () {
                        setEditState(true);
                    } }))),
        react_1.default.createElement("div", { className: "flex flex-col gap-2" },
            react_1.default.createElement("div", { className: "flex gap-4" },
                react_1.default.createElement("div", { className: "flex items-center justify-center" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTrophy })),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(loyalty === null || loyalty === void 0 ? void 0 : loyalty.loyalty_number), size: "xsmall", textColor: "text-inputText" }))),
            react_1.default.createElement("div", { className: "flex gap-4" },
                react_1.default.createElement("div", { className: "flex items-center justify-center w-4" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCreditCard })),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat((_e = loyalty === null || loyalty === void 0 ? void 0 : loyalty.card) === null || _e === void 0 ? void 0 : _e.number), size: "xsmall", textColor: "text-inputText" }))),
            react_1.default.createElement("div", { className: "flex gap-4" },
                react_1.default.createElement("div", { className: "flex items-center justify-center w-4" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCalendar })),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatDateTime(loyalty === null || loyalty === void 0 ? void 0 : loyalty.member_date, "YYYY-MM-DD"), size: "xsmall", textColor: "text-inputText" }))))))));
};
exports.CustomerLoyaltyDetails = CustomerLoyaltyDetails;

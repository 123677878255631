"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsLoading = exports.setStatistics = exports.clearState = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    statistics: null,
    isLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "statisticState",
    initialState: initialState,
    reducers: {
        clearState: function (state) {
            state.statistics = initialState.statistics;
            state.isLoading = initialState.isLoading;
        },
        setStatistics: function (state, action) {
            state.statistics = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
    },
});
exports.clearState = (_a = slice.actions, _a.clearState), exports.setStatistics = _a.setStatistics, exports.setIsLoading = _a.setIsLoading;
exports.default = slice.reducer;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validationConfig = exports.initialBranchData = exports.storeStatus = exports.actionOptions = void 0;
var constants_1 = require("./components/constants");
exports.actionOptions = [
    {
        name: "Add",
        value: "add",
    },
    {
        name: "Update",
        value: "update",
    },
];
exports.storeStatus = [
    {
        id: 1,
        status: "Open",
    },
    {
        id: 2,
        status: "Closed",
    },
    {
        id: 3,
        status: "Open 24hrs",
    },
];
exports.initialBranchData = {
    franchise: true,
    display_name: "",
    branch_number: "",
    email: "",
    offline: true,
    image_url: "",
    registered_name: "",
    registration_number: "",
    vat_registered: true,
    vat_number: "",
    contact_number: "",
    country_code: "+27",
    country_code_extension: "ZA",
    address: {},
    banking_details: constants_1.initialBankingDetailsData,
    delivery: constants_1.initialDeliverySettingsValues,
    stock_replacement: {
        replace_by_default: true,
    },
};
exports.validationConfig = {
    image_url: {
        dependOn: {
            key: "display_name",
            value: true,
        },
    },
    banking_details: {
        dependOn: {
            key: "franchise",
            value: true,
        },
    },
    vat_number: {
        dependOn: {
            key: "vat_registered",
            value: true,
        },
    },
};

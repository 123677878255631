"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateErrorList = void 0;
var generateErrorList = function (errorObject) {
    var errorList = [];
    for (var key in errorObject) {
        var error = errorObject[key];
        if (!error) {
            continue;
        }
        if (Array.isArray(error)) {
            errorList.push.apply(errorList, (0, exports.generateErrorList)(error));
            continue;
        }
        if (typeof error === "object") {
            errorList.push.apply(errorList, (0, exports.generateErrorList)(error));
            continue;
        }
        errorList.push(" \u2022 ".concat(error));
    }
    return errorList;
};
exports.generateErrorList = generateErrorList;

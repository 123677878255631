"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengePage = void 0;
var react_1 = __importStar(require("react"));
require("reactflow/dist/base.css");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var challenge_1 = require("../../../../reducers/slices/challenge/challenge");
var react_router_dom_1 = require("react-router-dom");
var get_challenge_thunk_1 = require("../../../../reducers/slices/challenge/thunks/get_challenge_thunk");
var console_1 = require("../../../../reducers/slices/console");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var save_challenge_thunk_1 = require("../../../../reducers/slices/challenge/thunks/save_challenge_thunk");
var routes_1 = require("../../../../constants/routes");
var main_1 = require("../../../../reducers/slices/main");
var constants_1 = require("./constants");
var pages_1 = require("../../../../constants/pages");
var get_category_thunk_1 = require("../../../../reducers/slices/products/categories/thunks/get_category_thunk");
var get_products_thunk_1 = require("../../../../reducers/slices/products/thunks/get_products_thunk");
var useSearchDebounce_1 = require("../../../../hooks/useSearchDebounce");
var useSkipFirstRender_1 = require("../../../../hooks/useSkipFirstRender");
var badge_list_1 = require("../badges/badge_list");
var xr_1 = require("../../../../helpers/xr");
var get_badges_thunk_1 = require("../../../../reducers/slices/badge/thunks/get_badges_thunk");
var challenge_segment_1 = require("./challenge_segment");
var inputLabelComponent_1 = require("../../../../components/inputLabelComponent");
var errors_1 = require("../../../../helpers/errors");
var ChallengePage = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
    var challenge_id = (0, react_router_dom_1.useParams)().challenge_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _8 = (0, react_redux_1.useSelector)(function (state) { return state.challengeState; }), selectedChallenge = _8.selectedChallenge, isLoading = _8.isLoading, isEdited = _8.isEdited, errors = _8.errors;
    var _9 = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), products = _9.products, productPagination = _9.pagination;
    var _10 = (0, react_redux_1.useSelector)(function (state) { return state.categoryState; }), categories = _10.categories, categoriesPagination = _10.pagination;
    var badges = (0, react_redux_1.useSelector)(function (state) { return state.badgeState; }).badges;
    var _11 = (0, useSearchDebounce_1.useSearchDebounce)(), search = _11[0], setSearch = _11[1];
    (0, react_1.useEffect)(function () {
        if (challenge_id) {
            dispatch((0, get_challenge_thunk_1.get_challenge_thunk)(challenge_id)).then(function (_a) {
                var payload = _a.payload;
                if (!(payload === null || payload === void 0 ? void 0 : payload.success)) {
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.CHALLENGES_ROUTE));
                }
            });
        }
        else {
            dispatch((0, console_1.setPageTitle)("Create a challenge"));
        }
        dispatch((0, get_category_thunk_1.get_category_thunk)({}));
        dispatch((0, get_products_thunk_1.get_products)({}));
        return function () {
            dispatch((0, challenge_1.clearState)());
        };
    }, []);
    (0, react_1.useEffect)(function () {
        var _a;
        if (challenge_id) {
            dispatch((0, console_1.setPageTitle)("Challenge - ".concat((_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.title) !== null && _a !== void 0 ? _a : challenge_id)));
        }
        else if (!(selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.item_id)) {
            var itemID = (0, xr_1.generateXRID)("m");
            dispatch((0, challenge_1.setSelectedChallenge)(__assign(__assign({}, selectedChallenge), { item_id: itemID })));
        }
    }, [selectedChallenge]);
    (0, react_1.useEffect)(function () {
        var _a, _b;
        if (((_b = (_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.type) === "badges") {
            dispatch((0, get_badges_thunk_1.get_badges_thunk)({}));
        }
    }, [(_b = (_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.type]);
    var onChange = function (challenge) {
        dispatch((0, challenge_1.setSelectedChallenge)(__assign(__assign({}, selectedChallenge), challenge)));
        if (!isEdited) {
            dispatch((0, challenge_1.setIsEdited)(true));
        }
    };
    var validate = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        var rewardValueError = "";
        if (((_b = (_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.type) === "badges") {
            rewardValueError = "Badge is required";
        }
        else if (((_d = (_c = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.type) === "currency") {
            rewardValueError = "Currency Value is required";
        }
        else if (((_f = (_e = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.type) === "xp") {
            rewardValueError = "XP Points is required";
        }
        var errors = {
            title: (selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.title) ? "" : "Title is required",
            event_type: (selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.event_type) ? "" : "Event type is required",
            trigger: {
                trigger_quantity: ((_g = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.trigger) === null || _g === void 0 ? void 0 : _g.trigger_quantity) ? "" : "Trigger quantity is required",
            },
            rewards: [
                {
                    type: ((_j = (_h = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.type) ? "" : "Reward type is required",
                    value: ((_l = (_k = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _k === void 0 ? void 0 : _k[0]) === null || _l === void 0 ? void 0 : _l.value) ? "" : rewardValueError,
                    currency_type: (function () {
                        var _a, _b, _c, _d, _e, _f;
                        if (((_b = (_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.type) === "currency") {
                            console.log((_d = (_c = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.currency_type);
                            return ((_f = (_e = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.currency_type) ? "" : "Currency type is required";
                        }
                    })(),
                    currency: (function () {
                        var _a, _b, _c, _d, _e, _f;
                        if (((_b = (_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.type) === "currency") {
                            console.log((_d = (_c = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.currency_type);
                            return ((_f = (_e = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.currency) ? "" : "Currency is required";
                        }
                    })(),
                },
            ],
            scope: {},
            schedule: {
                start_date: ((_m = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.schedule) === null || _m === void 0 ? void 0 : _m.start_date) ? "" : "Start date is required",
                end_date: ((_o = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.schedule) === null || _o === void 0 ? void 0 : _o.end_date) ? "" : "End date is required",
            },
        };
        if ((selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.event_type) && (selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.event_type) !== "numberOfLogins") {
            if (((_p = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.scope) === null || _p === void 0 ? void 0 : _p.type) === "category") {
                errors.scope = { value: ((_q = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.scope) === null || _q === void 0 ? void 0 : _q.value) ? "" : "Category is required" };
            }
            else if (((_r = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.scope) === null || _r === void 0 ? void 0 : _r.type) === "product") {
                errors.scope = { value: ((_s = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.scope) === null || _s === void 0 ? void 0 : _s.value) ? "" : "Product is required" };
            }
            else if (!((_t = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.scope) === null || _t === void 0 ? void 0 : _t.type)) {
                errors.scope = { type: "Scope type is required" };
            }
        }
        dispatch((0, challenge_1.setErrors)(errors));
        return (0, errors_1.generateErrorList)(errors);
    };
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errorList, challengePayload, payload_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    errorList = validate();
                    if (errorList === null || errorList === void 0 ? void 0 : errorList.length) {
                        dispatch((0, main_1.setMessageModal)({ title: "Validation Error", messages: __spreadArray(["Please correct the following errors: ", ""], errorList, true) }));
                        return [2 /*return*/];
                    }
                    challengePayload = __assign({}, selectedChallenge);
                    if (!(challenge_id && (selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.item_id))) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch((0, save_challenge_thunk_1.save_challenge_thunk)(challengePayload))];
                case 1:
                    _c.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, dispatch((0, save_challenge_thunk_1.save_challenge_thunk)(challengePayload))];
                case 3:
                    payload_1 = (_c.sent()).payload;
                    if ((payload_1 === null || payload_1 === void 0 ? void 0 : payload_1.success) && ((_a = payload_1.content) === null || _a === void 0 ? void 0 : _a._id)) {
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.CHALLENGE_DETAILS_ROUTE, "/").concat((_b = payload_1 === null || payload_1 === void 0 ? void 0 : payload_1.content) === null || _b === void 0 ? void 0 : _b._id));
                    }
                    if (payload_1.success) {
                        dispatch((0, main_1.setContentModal)({
                            title: "Challenge creation confirmation",
                            content: (react_1.default.createElement("div", { className: "flex flex-col center justify-evenly text-center gap-8 max-w-md" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Your challenge has been added successfully.", textColor: "text-primary", size: "3xlarge" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You can proceed to linking your challenge to a segment.", textColor: "text-primary", size: "xlarge" }),
                                react_1.default.createElement("div", { className: "flex flex-row gap-4 justify-center" },
                                    react_1.default.createElement("div", { className: "w-[150px]" },
                                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", backgroundColor: "bg-transparent", borderColor: "border-primary", textColor: "text-primary", onClick: function () {
                                                dispatch((0, main_1.setContentModal)(null));
                                            } })),
                                    react_1.default.createElement("div", { className: "w-[150px]" },
                                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Proceed", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                                                dispatch((0, main_1.setContentModal)({
                                                    title: "Select Segment",
                                                    content: (react_1.default.createElement(challenge_segment_1.ChallengeSegment, { onSave: function (segment_id) {
                                                            var _a;
                                                            console.log(selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge._id);
                                                            dispatch((0, save_challenge_thunk_1.save_challenge_segment_thunk)({ challenge_id: (_a = payload_1.content) === null || _a === void 0 ? void 0 : _a._id, segment_id: segment_id }));
                                                            dispatch((0, main_1.setContentModal)(null));
                                                        } })),
                                                }));
                                            } }))))),
                        }));
                    }
                    _c.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var getClientProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_products_thunk_1.get_products)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var getClientCategories = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_category_thunk_1.get_category_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchClientProducts = function (search_string) { return getClientProducts(__assign(__assign({}, productPagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" })); };
    var onSearchClientCategories = function (search_string) { return getClientCategories(__assign(__assign({}, categoriesPagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" })); };
    (0, useSkipFirstRender_1.useSkipFirstRender)(function () {
        var _a, _b, _c, _d;
        if (search) {
            if (((_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.scope) === null || _a === void 0 ? void 0 : _a.type) === "product") {
                onSearchClientProducts(search);
            }
            else if (((_b = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.scope) === null || _b === void 0 ? void 0 : _b.type) === "category") {
                onSearchClientCategories(search);
            }
        }
        else {
            if (((_c = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.scope) === null || _c === void 0 ? void 0 : _c.type) === "product") {
                getClientProducts({});
            }
            else if (((_d = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.scope) === null || _d === void 0 ? void 0 : _d.type) === "category") {
                getClientCategories({});
            }
        }
    }, [search]);
    var challengeOptions = categories.map(function (category) { return ({ label: category.name, value: category._id }); });
    var productsOptions = products.map(function (product) { return ({ label: product.title, value: product._id }); });
    var isScopeDisabled = !(selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.event_type) || (selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.event_type) === "numberOfLogins";
    var rewardsElements = function (reward) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        switch (reward === null || reward === void 0 ? void 0 : reward.type) {
            case "vouchers":
                return (react_1.default.createElement("div", { className: "gap-2 max-w-[50%]" },
                    react_1.default.createElement("div", { className: "row" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Voucher", textColor: "text-primary" })),
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "", options: constants_1.challengeVoucherTypeOptions, value: reward === null || reward === void 0 ? void 0 : reward.type, required: true, size: "small", borderColor: "border-blue-300", textColor: "text-inputText", onChange: function (type) {
                            var _a;
                            onChange({ rewards: [__assign(__assign({}, (_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _a === void 0 ? void 0 : _a[0]), { type: type })] });
                        }, errorText: (_b = (_a = errors === null || errors === void 0 ? void 0 : errors.rewards) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.type })));
            case "badges":
                var getRewardBadgeLabel = function (reward_value) {
                    var _a, _b, _c;
                    if (reward_value) {
                        return (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: reward_value ? (_a = badges.find(function (badge) { return badge.item_id === reward_value; })) === null || _a === void 0 ? void 0 : _a.name : "", textColor: "text-primary", className: "mt-4" }));
                    }
                    else if ((_c = (_b = errors === null || errors === void 0 ? void 0 : errors.rewards) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.value) {
                        return react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: errors.rewards[0].value, textColor: "text-red-500", className: "mt-4", size: "small" });
                    }
                };
                return (react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-4 items-center" },
                    react_1.default.createElement("div", { className: "mt-4" },
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Select Badge", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                                dispatch((0, main_1.setContentModal)({
                                    title: "Select Badge",
                                    content: (react_1.default.createElement(badge_list_1.BadgesPage, { isSubComponent: true, isEditable: false, onRowClick: function (_a) {
                                            var _b;
                                            var item_id = _a.item_id;
                                            onChange({ rewards: [__assign(__assign({}, (_b = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _b === void 0 ? void 0 : _b[0]), { value: item_id })] });
                                            dispatch((0, main_1.setContentModal)(null));
                                        } })),
                                }));
                            } })),
                    react_1.default.createElement("div", { className: "flex flex-row gap-2" }, getRewardBadgeLabel(reward.value))));
            case "xp":
                return (react_1.default.createElement("div", { className: "gap-2 max-w-[50%]" },
                    react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "XP Points", textColor: "text-primary", required: true }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: String(reward === null || reward === void 0 ? void 0 : reward.value), onChange: function (value) {
                            var _a;
                            onChange({ rewards: [__assign(__assign({}, (_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _a === void 0 ? void 0 : _a[0]), { value: value })] });
                        }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", errorText: (_d = (_c = errors === null || errors === void 0 ? void 0 : errors.rewards) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.value, type: "number" })));
            case "currency":
                return (react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-4" },
                    react_1.default.createElement("div", { className: "gap-2" },
                        react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Currency Type", textColor: "text-primary", required: true }),
                        react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "", options: constants_1.challengeCurrencyTypeOptions, value: (_f = (_e = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.currency_type, required: true, size: "small", borderColor: "border-blue-300", textColor: "text-inputText", onChange: function (currency_type) {
                                var _a;
                                onChange({ rewards: [__assign(__assign({}, (_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _a === void 0 ? void 0 : _a[0]), { currency_type: currency_type })] });
                            }, errorText: (_h = (_g = errors === null || errors === void 0 ? void 0 : errors.rewards) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.currency_type })),
                    react_1.default.createElement("div", { className: "gap-2" },
                        react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Select a Virtual Currency", textColor: "text-primary", required: true }),
                        react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "", options: constants_1.challengeVirtualCurrencyOptions, value: (_k = (_j = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.currency, required: true, size: "small", textColor: "text-inputText", onChange: function (currency) {
                                var _a;
                                onChange({ rewards: [__assign(__assign({}, (_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _a === void 0 ? void 0 : _a[0]), { currency: currency })] });
                            }, errorText: (_m = (_l = errors === null || errors === void 0 ? void 0 : errors.rewards) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.currency })),
                    react_1.default.createElement("div", { className: "gap-2" },
                        react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Currency Value", textColor: "text-primary", required: true }),
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: String(reward === null || reward === void 0 ? void 0 : reward.value), onChange: function (value) {
                                var _a;
                                onChange({ rewards: [__assign(__assign({}, (_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _a === void 0 ? void 0 : _a[0]), { value: value })] });
                            }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", type: "number", errorText: (_p = (_o = errors === null || errors === void 0 ? void 0 : errors.rewards) === null || _o === void 0 ? void 0 : _o[0]) === null || _p === void 0 ? void 0 : _p.value }))));
            default:
                return null;
        }
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 pb-4 h-full" },
        react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2" },
            react_1.default.createElement("div", { className: "flex flex-row" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: "Back to challenges", size: "xsmall", startIcon: pro_light_svg_icons_1.faChevronLeft, backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.CHALLENGES_ROUTE));
                    } }))),
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-row gap-4 justify-between items-center" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Customize your challenge", textColor: "text-back", size: "large", bold: true }),
            react_1.default.createElement("div", { className: "grid" },
                react_1.default.createElement("div", { className: "grow min-w-[125px]" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: onSave, loading: isLoading, enabled: isEdited })))),
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col h-full overflow-auto gap-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Challenge Details", textColor: "text-primary", size: "large", bold: true }),
            react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-6 h-full justify-between" },
                react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Define Challenge", textColor: "text-primary", bold: true, className: "bg-blue-50 p-1" }),
                    react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-4" },
                        react_1.default.createElement("div", { className: "gap-2" },
                            react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Challenge Title", textColor: "text-primary", required: true }),
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.title, onChange: function (title) {
                                    onChange({ title: title });
                                }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", errorText: errors === null || errors === void 0 ? void 0 : errors.title })),
                        react_1.default.createElement("div", { className: "gap-2" },
                            react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Event Trigger", textColor: "text-primary", required: true }),
                            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "", options: constants_1.challengeEventTypeOptions, value: selectedChallenge.event_type, required: true, size: "small", borderColor: "border-blue-300", textColor: "text-inputText", errorTextColor: "text-red-500", onChange: function (event_type) {
                                    onChange({ event_type: event_type });
                                }, errorText: errors === null || errors === void 0 ? void 0 : errors.event_type })))),
                react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Trigger Conditions", textColor: "text-primary", bold: true, className: "bg-blue-50  p-1" }),
                    react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-4 items-center" },
                        ((selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.event_type) === "numberOfLogins" || (selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.event_type) === "orderVolume") && (react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: (selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.event_type) === "numberOfLogins" ? "Track consecutive login days" : "Consecutive order days", value: (_c = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.trigger) === null || _c === void 0 ? void 0 : _c.consecutive_count, onChange: function (consecutive_count) {
                                onChange({ trigger: __assign(__assign({}, selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.trigger), { consecutive_count: consecutive_count }) });
                            }, textColor: "text-primary" })),
                        react_1.default.createElement("div", { className: "gap-2" },
                            react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Trigger Quantity", textColor: "text-primary", required: true }),
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: String((_d = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.trigger) === null || _d === void 0 ? void 0 : _d.trigger_quantity), onChange: function (trigger_quantity) {
                                    onChange({ trigger: __assign(__assign({}, selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.trigger), { trigger_quantity: parseInt(trigger_quantity) }) });
                                }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", type: "number", errorText: (_e = errors === null || errors === void 0 ? void 0 : errors.trigger) === null || _e === void 0 ? void 0 : _e.trigger_quantity })))),
                react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Scope of Application", textColor: "text-primary", bold: true, className: "bg-blue-50 p-1" }),
                    react_1.default.createElement("div", { className: "gap-2" },
                        react_1.default.createElement("div", { className: "grid w-full" },
                            react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Apply the criteria to", cols: "grid-cols-3", options: constants_1.challengeScopeOptions, selectedValue: String((_f = selectedChallenge.scope) === null || _f === void 0 ? void 0 : _f.type), onChange: function (type) {
                                    if (isScopeDisabled) {
                                        return;
                                    }
                                    onChange({ scope: __assign(__assign({}, selectedChallenge.scope), { type: type }) });
                                }, colors: __assign(__assign({}, pages_1.radioButtonColorConfig), (isScopeDisabled && { titleColor: "text-disabled font-normal", radio: { textColor: "text-disabled", iconColor: "text-disabled" } })), required: true, errorText: (_g = errors.scope) === null || _g === void 0 ? void 0 : _g.type })),
                        react_1.default.createElement("div", { className: "grid grid-cols-2 gap-2" },
                            react_1.default.createElement("div", { className: "gap-2" },
                                react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Category", textColor: ((_h = selectedChallenge.scope) === null || _h === void 0 ? void 0 : _h.type) === "category" && !isScopeDisabled ? "text-inputText" : "text-disabled", required: ((_j = selectedChallenge.scope) === null || _j === void 0 ? void 0 : _j.type) === "category" }),
                                react_1.default.createElement(doshx_controls_web_1.DropdownSearchControl, { label: "", options: challengeOptions, renderDisplay: function (category) { return category === null || category === void 0 ? void 0 : category.label; }, value: challengeOptions.find(function (category) { var _a; return category.value === ((_a = selectedChallenge.scope) === null || _a === void 0 ? void 0 : _a.value); }), required: true, size: "small", colors: {
                                        borderColor: ((_k = selectedChallenge.scope) === null || _k === void 0 ? void 0 : _k.type) === "category" && !isScopeDisabled ? "border-blue-300" : "border-disabled",
                                        textColor: ((_l = selectedChallenge.scope) === null || _l === void 0 ? void 0 : _l.type) === "category" && !isScopeDisabled ? "text-inputText" : "text-disabled",
                                        iconColor: ((_m = selectedChallenge.scope) === null || _m === void 0 ? void 0 : _m.type) === "category" && !isScopeDisabled ? "text-inputText" : "text-disabled",
                                    }, onChange: function (category) {
                                        console.log(category);
                                        onChange({ scope: __assign(__assign({}, selectedChallenge.scope), { value: category.value }) });
                                    }, onSearchChanged: setSearch, disabled: !((_o = selectedChallenge.scope) === null || _o === void 0 ? void 0 : _o.type) || ((_p = selectedChallenge.scope) === null || _p === void 0 ? void 0 : _p.type) !== "category" || isScopeDisabled, errorText: ((_q = selectedChallenge.scope) === null || _q === void 0 ? void 0 : _q.type) === "category" ? (_r = errors.scope) === null || _r === void 0 ? void 0 : _r.value : "" })),
                            react_1.default.createElement("div", { className: "gap-2" },
                                react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Product", textColor: ((_s = selectedChallenge.scope) === null || _s === void 0 ? void 0 : _s.type) === "product" && !isScopeDisabled ? "text-inputText" : "text-disabled", required: ((_t = selectedChallenge.scope) === null || _t === void 0 ? void 0 : _t.type) === "product" }),
                                react_1.default.createElement(doshx_controls_web_1.DropdownSearchControl, { label: "", options: productsOptions, renderDisplay: function (product) { return product === null || product === void 0 ? void 0 : product.label; }, value: productsOptions.find(function (product) { var _a; return product.value === ((_a = selectedChallenge.scope) === null || _a === void 0 ? void 0 : _a.value); }), required: true, size: "small", colors: {
                                        borderColor: ((_u = selectedChallenge.scope) === null || _u === void 0 ? void 0 : _u.type) === "product" && !isScopeDisabled ? "border-blue-300" : "border-disabled",
                                        textColor: ((_v = selectedChallenge.scope) === null || _v === void 0 ? void 0 : _v.type) === "product" && !isScopeDisabled ? "text-inputText" : "text-disabled",
                                        iconColor: ((_w = selectedChallenge.scope) === null || _w === void 0 ? void 0 : _w.type) === "product" && !isScopeDisabled ? "text-inputText" : "text-disabled",
                                    }, onChange: function (product) {
                                        console.log(product);
                                        onChange({ scope: __assign(__assign({}, selectedChallenge.scope), { value: product.value }) });
                                    }, onSearchChanged: setSearch, disabled: !((_x = selectedChallenge.scope) === null || _x === void 0 ? void 0 : _x.type) || ((_y = selectedChallenge.scope) === null || _y === void 0 ? void 0 : _y.type) !== "product" || isScopeDisabled, errorText: ((_z = selectedChallenge.scope) === null || _z === void 0 ? void 0 : _z.type) === "product" ? (_0 = errors.scope) === null || _0 === void 0 ? void 0 : _0.value : "" }))))),
                react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Link Rewards to Challenge Completion", textColor: "text-primary", bold: true, className: "bg-blue-50 p-1" }),
                    react_1.default.createElement("div", { className: "gap-2" },
                        react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Linking Rewards to Challenges", cols: "grid-cols-4", options: constants_1.challengeRewardOptions, selectedValue: String((_2 = (_1 = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _1 === void 0 ? void 0 : _1[0]) === null || _2 === void 0 ? void 0 : _2.type), onChange: function (type) {
                                var _a;
                                onChange({ rewards: [__assign(__assign({}, (_a = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _a === void 0 ? void 0 : _a[0]), { type: type })] });
                            }, colors: pages_1.radioButtonColorConfig, required: true, errorText: (_4 = (_3 = errors === null || errors === void 0 ? void 0 : errors.rewards) === null || _3 === void 0 ? void 0 : _3[0]) === null || _4 === void 0 ? void 0 : _4.type }),
                        rewardsElements((_5 = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.rewards) === null || _5 === void 0 ? void 0 : _5[0]))),
                react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Schedule", textColor: "text-primary", bold: true, className: "bg-blue-50 p-1" }),
                    react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-4" },
                        react_1.default.createElement("div", { className: "gap-2" },
                            react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Start Date", textColor: "text-primary", required: true }),
                            react_1.default.createElement(doshx_controls_web_1.DateTimeControl, { value: (_6 = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.schedule) === null || _6 === void 0 ? void 0 : _6.start_date, onChange: function (start_date) {
                                    onChange({ schedule: __assign(__assign({}, selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.schedule), { start_date: start_date }) });
                                }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText" })),
                        react_1.default.createElement("div", { className: "gap-2" },
                            react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "End Date", textColor: "text-primary", required: true }),
                            react_1.default.createElement(doshx_controls_web_1.DateTimeControl, { value: (_7 = selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.schedule) === null || _7 === void 0 ? void 0 : _7.end_date, onChange: function (end_date) {
                                    onChange({ schedule: __assign(__assign({}, selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.schedule), { end_date: end_date }) });
                                }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText" })))),
                react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Configure", textColor: "text-primary", bold: true, className: " bg-blue-50 p-1" }),
                    react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-4 items-center" },
                        react_1.default.createElement("div", { className: "gap-2" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Status", textColor: "text-primary" }),
                            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "", options: constants_1.challengeStatusOptions, value: selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.status, required: true, size: "small", borderColor: "border-blue-300", textColor: "text-inputText", onChange: function (status) {
                                    onChange({ status: status });
                                } })),
                        react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Visible to users", value: selectedChallenge === null || selectedChallenge === void 0 ? void 0 : selectedChallenge.visible, onChange: function (visible) {
                                onChange({ visible: visible });
                            }, textColor: "text-primary" })))))));
};
exports.ChallengePage = ChallengePage;

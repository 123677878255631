"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressComponent = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var get_address_list_1 = require("../api/calls/user/get/get_address_list");
var main_1 = require("../reducers/slices/main");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var add_user_address_1 = require("../api/calls/user/create/add_user_address");
var remove_user_address_1 = require("../api/calls/user/remove/remove_user_address");
var AddressComponent = function (props) {
    var customerId = props.customerId;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(false), isEditing = _a[0], setIsEditing = _a[1];
    var _b = (0, react_1.useState)(), selectedAddress = _b[0], setSelectedAddress = _b[1];
    var _c = (0, react_1.useState)([]), addressList = _c[0], setAddressList = _c[1];
    var _d = (0, react_1.useState)(""), newAddressName = _d[0], setNewAddressName = _d[1];
    var _e = (0, react_1.useState)(""), addressLabelErrorText = _e[0], setAddressLabelErrorText = _e[1];
    var _f = (0, react_1.useState)(-1), deletingIndex = _f[0], setDeletingIndex = _f[1];
    var _g = (0, react_1.useState)(false), isSaving = _g[0], setIsSaving = _g[1];
    var _h = (0, react_1.useState)(false), isDeleting = _h[0], setIsDeleting = _h[1];
    (0, react_1.useEffect)(function () {
        getAddresses();
    }, []);
    var getAddresses = function () {
        if (customerId) {
            (0, get_address_list_1.address_list)({
                user_id: customerId,
            }).then(function (response) {
                if (response.success) {
                    setAddressList(response.content.addresses);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                }
            });
        }
    };
    var validateAddress = function () {
        var messages = [];
        var errorCount = 0;
        setAddressLabelErrorText("");
        if (!selectedAddress) {
            messages.push("Please select an address. When you searched, please click on a prediction to select it.");
        }
        if (!newAddressName) {
            errorCount++;
            setAddressLabelErrorText("Label is required for your new address.");
        }
        if (messages.length > 0 || errorCount > 0) {
            if (messages.length > 0) {
                dispatch((0, main_1.setMessageModal)({ title: "Please select address!", messages: messages }));
            }
            return false;
        }
        else {
            return true;
        }
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 p-4 border border-borders rounded-lg" },
        react_1.default.createElement("div", { className: "flex flex-row" },
            react_1.default.createElement("div", { className: "flex-1" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Addresses", size: "small", textColor: "text-inputText", bold: true })),
            react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: isEditing ? pro_solid_svg_icons_1.faSave : pro_solid_svg_icons_1.faAdd, backgroundColor: "bg-success", borderColor: "border-success", iconColor: "text-white", size: "xsmall", loading: isSaving, onClick: function () {
                        if (isEditing) {
                            if (validateAddress()) {
                                setIsSaving(true);
                                (0, add_user_address_1.add_user_address)({
                                    user_id: customerId,
                                    address: {
                                        label: newAddressName,
                                        full_address: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.full_address,
                                        latitude: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.latitude,
                                        longitude: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.longitude,
                                        default: false,
                                    },
                                }).then(function (response) {
                                    setIsSaving(false);
                                    if (response.success) {
                                        setIsEditing(false);
                                        getAddresses();
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                                    }
                                });
                            }
                        }
                        else {
                            setIsEditing(true);
                        }
                    } }),
                isEditing && (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faClose, backgroundColor: "bg-error", borderColor: "border-error", iconColor: "text-white", size: "xsmall", onClick: function () {
                        setIsEditing(false);
                    } })))),
        isEditing ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.AddressSearchControl, { letsTradeAPIUrl: process.env.LETS_TRADE_CLIENT_URL, required: true, placeholder: "Search & select address...", size: "small", onSelectionChange: function (value) {
                    setSelectedAddress({
                        full_address: value.fullAddress,
                        longitude: value.coordinates.lng,
                        latitude: value.coordinates.lat,
                    });
                } }),
            react_1.default.createElement("div", null, (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.full_address) && (react_1.default.createElement("div", { className: "flex flex-col gap-1" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", size: "small", borderColor: "border-borders", onChange: function (v) { return setNewAddressName(v); }, errorText: addressLabelErrorText }),
                react_1.default.createElement("div", { className: "flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: selectedAddress.full_address, textColor: "text-black", size: "xsmall" }))))))) : (react_1.default.createElement("div", { className: "" }, addressList && addressList.length > 0 ? (addressList.map(function (address, index) {
            return (react_1.default.createElement("div", { key: index, className: "".concat(index == 0 ? "" : "border-t border-borders", " mb-2 pt-2 flex flex-row gap-4 items-center") },
                react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: address.label, bold: true, textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: address.full_address })),
                react_1.default.createElement("div", null,
                    react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faTrashCan, backgroundColor: "bg-error", iconColor: "text-white", size: "xsmall", loading: index == deletingIndex && isDeleting, onClick: function () {
                            setIsDeleting(true);
                            setDeletingIndex(index);
                            (0, remove_user_address_1.delete_address)({
                                _id: address === null || address === void 0 ? void 0 : address._id,
                            }).then(function (response) {
                                setIsDeleting(false);
                                setDeletingIndex(-1);
                                if (response.success) {
                                    getAddresses();
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Something went wrong!",
                                        messages: [response.message],
                                    }));
                                }
                            });
                        } }))));
        })) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "No addresses added as yet!" }))))));
};
exports.AddressComponent = AddressComponent;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeDashboardView = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var dashboardFilterPeriods_1 = require("../helpers/dashboardHelpers/dashboardFilterPeriods");
// import { get_logged_in_client_user_branches } from "../api/calls/client/get_client_user_branches";
var get_client_user_list_thunk_1 = require("../reducers/slices/client/thunks/get_client_user_list_thunk");
// import { user_branch_list_thunk } from "../reducers/slices/branch/thunks/get/branch_list_thunk";
// import { get_order_client_dashboard_thunk } from "../reducers/slices/dashboard/thunks/get_client_order_dashboard_thunk";
// import { get_basket_client_dashboard_thunk } from "../reducers/slices/dashboard/thunks/get_client_basket_dashboard_thunk";
// import { IDashboardSliceState } from "../reducers/slices/dashboard/dashboard";
// import { get_client_user_dashboard_thunk } from "../reducers/slices/dashboard/thunks/get_client_user_dashboard_thunk";
var useSearchDebounce_1 = require("../hooks/useSearchDebounce");
// import { IBranchSliceState } from "../reducers/slices/branch/branch";
var HomeDashboardView = function () {
    var _a, _b;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, useSearchDebounce_1.useSearchDebounce)(1100), searchDashboardBranches = _c[0], setSearchDashboardBranches = _c[1];
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _d.user, client_token = _d.client_token;
    var _e = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }), clientUsers = _e.clientUsers, pagination = _e.pagination, client = _e.client;
    // const { dashboardBranches, dashboardBranchPagination } = useSelector<any, IBranchSliceState>((state) => state.branchState);
    // const { branchDashboard, orderRevenue, orderCount, orderStatusGrouping, basketStatusGrouping, usersDashboard } = useSelector<any, IDashboardSliceState>(
    //   (state) => state.dashboardState,
    // );
    var _f = (0, react_1.useState)((_b = (_a = dashboardFilterPeriods_1.dashboardFilterPeriods[0]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : ""), selectedPeriod = _f[0], setSelectedPeriod = _f[1];
    // const query = { ...dashboardBranchPagination, is_dashboard_request: true };
    //clientUser branches
    var _g = (0, react_1.useState)([]), clientUserBranches = _g[0], setClientUserBranches = _g[1];
    var _h = (0, react_1.useState)(undefined), selectedBranchId = _h[0], setSelectedBranchId = _h[1];
    // const [branch, setBranch] = useState<any>("");
    (0, react_1.useEffect)(function () {
        if (client_token && (user === null || user === void 0 ? void 0 : user.role) === "ADMIN") {
            getClientUsers(__assign({}, pagination));
            // dispatch(get_order_client_dashboard_thunk({ period: selectedPeriod, branch_id: selectedBranchId }));
            // dispatch(get_basket_client_dashboard_thunk({ period: selectedPeriod, branch_id: selectedBranchId }));
            // dispatch(get_client_user_dashboard_thunk({ period: selectedPeriod, branch_id: selectedBranchId }));
        }
    }, [client_token, user, selectedPeriod, selectedBranchId]);
    (0, react_1.useEffect)(function () {
        if (client_token && (user === null || user === void 0 ? void 0 : user.role) === "AREA_MANAGER") {
            getClientUsers(__assign({}, pagination));
            // dispatch(get_order_client_dashboard_thunk({ period: selectedPeriod, branch_id: selectedBranchId }));
            // dispatch(get_basket_client_dashboard_thunk({ period: selectedPeriod, branch_id: selectedBranchId }));
        }
    }, [client_token, user, selectedPeriod, selectedBranchId]);
    (0, react_1.useEffect)(function () {
        if (client_token && (user === null || user === void 0 ? void 0 : user.role) === "BRANCH_MANAGER") {
            getClientUsers(__assign({}, pagination));
            // dispatch(get_order_client_dashboard_thunk({ period: selectedPeriod, branch_id: selectedBranchId }));
            // dispatch(get_basket_client_dashboard_thunk({ period: selectedPeriod, branch_id: selectedBranchId }));
        }
    }, [client_token, user, selectedPeriod, selectedBranchId]);
    // useEffect(() => {
    //   if (clientUsers) {
    //     getLoggedInUserBranches();
    //   }
    // }, [clientUsers]);
    // useEffect(() => {
    //   onSearchBranches(searchDashboardBranches);
    // }, [searchDashboardBranches]);
    var getClientUsers = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, dispatch((0, get_client_user_list_thunk_1.get_client_user_list_thunk)(query))];
    }); }); };
    // const getBranches = async (query: any) => await dispatch(user_branch_list_thunk(query));
    // const onSearchBranches = (search_string: any) => {
    //   getBranches({ ...dashboardBranchPagination, ...(search_string && { search_string: search_string }), is_dashboard_request: true });
    // };
    // const getLoggedInUserBranches = async () => {
    //   const loggedInUser = clientUsers?.find((clientUser: IUser) => clientUser.user_id === user?._id);
    //   if (user && user?.role !== "ADMIN") {
    //     get_logged_in_client_user_branches({
    //       branch_id_list: loggedInUser?.manager_of,
    //     }).then((response) => {
    //       if (response.success) {
    //         setClientUserBranches(response?.content?.branch_list);
    //         setSelectedBranchId(response?.content?.branch_list[0]?._id);
    //       }
    //     });
    //   } else if (user?.role === "ADMIN") {
    //     const response = await dispatch(user_branch_list_thunk({ ...dashboardBranchPagination, ...query }));
    //     if (response?.payload?.success) {
    //       setClientUserBranches(response?.payload?.content?.branch_list);
    //     }
    //   }
    // };
    return (react_1.default.createElement("div", { className: "pb-4" },
        react_1.default.createElement("div", { className: "flex space-x-4 pt-6 items-center" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Welcome,", size: "large", textColor: "text-black", bold: true }),
            react_1.default.createElement("span", { className: "italic text-xl" }, user === null || user === void 0 ? void 0 :
                user.name,
                " ", user === null || user === void 0 ? void 0 :
                user.surname)),
        react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4" }),
        react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4" })));
};
exports.HomeDashboardView = HomeDashboardView;

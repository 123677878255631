"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialEconomyState = exports.setErrors = exports.setPagination = exports.setIsEdited = exports.setIsLoading = exports.setEarnConfigs = exports.setSelectedEarnConfig = exports.setCurrencies = exports.clearState = exports.setSelectedCurrency = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    selectedCurrency: {
        name: "",
        code: "",
        type: "",
        conversion_rate: 0,
        icon: {
            name: "",
            prefix: "",
            url: "",
            etag: "",
        },
    },
    currencies: [],
    selectedEarnConfig: {
        trigger_value: 0,
        type: null,
        earn_target: "",
        earn_amount: 0,
        excluded_product_ids: [],
        excluded_category_ids: [],
        segment_id: "",
        exclude_type: "product",
    },
    earn_configs: [],
    pagination: pages_1.initialPaginationValues,
    isLoading: false,
    isEdited: false,
    errors: {},
};
exports.initialEconomyState = initialState;
var slice = (0, toolkit_1.createSlice)({
    name: "economicsState",
    initialState: initialState,
    reducers: {
        setSelectedCurrency: function (state, action) {
            state.selectedCurrency = action.payload;
        },
        clearState: function (state) {
            state.selectedCurrency = initialState.selectedCurrency;
            state.currencies = initialState.currencies;
            state.selectedEarnConfig = initialState.selectedEarnConfig;
            state.earn_configs = initialState.earn_configs;
            state.pagination = initialState.pagination;
            state.isLoading = initialState.isLoading;
            state.isEdited = initialState.isEdited;
            state.errors = initialState.errors;
        },
        setCurrencies: function (state, action) {
            state.currencies = action.payload;
        },
        setSelectedEarnConfig: function (state, action) {
            state.selectedEarnConfig = action.payload;
        },
        setEarnConfigs: function (state, action) {
            state.earn_configs = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setIsEdited: function (state, action) {
            state.isEdited = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setErrors: function (state, action) {
            state.errors = action.payload;
        },
    },
});
exports.setSelectedCurrency = (_a = slice.actions, _a.setSelectedCurrency), exports.clearState = _a.clearState, exports.setCurrencies = _a.setCurrencies, exports.setSelectedEarnConfig = _a.setSelectedEarnConfig, exports.setEarnConfigs = _a.setEarnConfigs, exports.setIsLoading = _a.setIsLoading, exports.setIsEdited = _a.setIsEdited, exports.setPagination = _a.setPagination, exports.setErrors = _a.setErrors;
exports.default = slice.reducer;

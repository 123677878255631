"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LevelProgressionPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var upload_file_1 = require("../../../../../api/calls/media/upload_file");
var inputLabelComponent_1 = require("../../../../../components/inputLabelComponent");
var constants_1 = require("../../../../../constants/constants");
var routes_1 = require("../../../../../constants/routes");
var errors_1 = require("../../../../../helpers/errors");
var badge_1 = require("../../../../../reducers/slices/badge/badge");
var console_1 = require("../../../../../reducers/slices/console");
var main_1 = require("../../../../../reducers/slices/main");
var reward_track_1 = require("../../../../../reducers/slices/reward_track/reward_track");
var get_reward_track_thunk_1 = require("../../../../../reducers/slices/reward_track/thunks/get_reward_track_thunk");
var save_reward_track_thunk_1 = require("../../../../../reducers/slices/reward_track/thunks/save_reward_track_thunk");
var get_statistics_thunk_1 = require("../../../../../reducers/slices/statistic/thunks/get_statistics_thunk");
var level_progression_milestone_1 = require("./level_progression_milestone");
require("reactflow/dist/base.css");
var LevelProgressionPage = function () {
    var _a, _b, _c;
    var reward_track_id = (0, react_router_dom_1.useParams)().reward_track_id;
    var _d = (0, react_1.useState)(false), isEdited = _d[0], setIsEdited = _d[1];
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _e = (0, react_redux_1.useSelector)(function (state) { return state.rewardTrackState; }), selectedLevelRewardTrack = _e.selectedLevelRewardTrack, isLoading = _e.isLoading, errors = _e.errors;
    var _f = (0, react_redux_1.useSelector)(function (state) { return state.statisticState; }), statistics = _f.statistics, statIsLoading = _f.isLoading;
    (0, react_1.useEffect)(function () {
        if (reward_track_id) {
            dispatch((0, get_reward_track_thunk_1.get_reward_track_thunk)({ _id: reward_track_id, reward_track_type: constants_1.RewardTrackTypes.LEVEL_PROGRESSION })).then(function (_a) {
                var payload = _a.payload;
                if (!(payload === null || payload === void 0 ? void 0 : payload.success)) {
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.REWARD_TRACKS_ROUTE));
                }
            });
        }
        else {
            dispatch((0, console_1.setPageTitle)("Create a level progression"));
        }
        dispatch((0, get_statistics_thunk_1.get_statistics_thunk)({}));
        return function () {
            dispatch((0, badge_1.clearState)());
        };
    }, []);
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d, _e;
        if (reward_track_id) {
            dispatch((0, console_1.setPageTitle)("Level Progression - ".concat((_a = selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.name) !== null && _a !== void 0 ? _a : reward_track_id)));
        }
        if (selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestones) {
            if (((_b = selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestones) === null || _b === void 0 ? void 0 : _b.length) < (selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestone_count)) {
                var milestones = __spreadArray([], selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestones, true);
                for (var i = (_c = selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestones) === null || _c === void 0 ? void 0 : _c.length; i < (selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestone_count); i++) {
                    milestones.push({ index: i, name: "", source_value: "0", target_value: "0", image_url: "", colour: "" });
                }
                dispatch((0, reward_track_1.setSelectedLevelRewardTrack)(__assign(__assign({}, selectedLevelRewardTrack), { milestones: milestones })));
            }
            else if (((_d = selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestones) === null || _d === void 0 ? void 0 : _d.length) > (selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestone_count)) {
                var milestones = __spreadArray([], (_e = selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestones) === null || _e === void 0 ? void 0 : _e.slice(0, selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestone_count), true);
                dispatch((0, reward_track_1.setSelectedLevelRewardTrack)(__assign(__assign({}, selectedLevelRewardTrack), { milestones: milestones })));
            }
        }
    }, [selectedLevelRewardTrack]);
    var onChange = function (rewardTrack) {
        dispatch((0, reward_track_1.setSelectedLevelRewardTrack)(__assign(__assign({}, selectedLevelRewardTrack), rewardTrack)));
        if (!isEdited) {
            setIsEdited(true);
        }
    };
    var validate = function () {
        var _a;
        var errors = {
            name: (selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.name) ? "" : "Name is required",
            source: (selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.source) ? "" : "Source is required",
            target: (selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.target) ? "" : "Target is required",
            milestones: (_a = selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestones) === null || _a === void 0 ? void 0 : _a.map(function (milestone) { return ({
                name: (milestone === null || milestone === void 0 ? void 0 : milestone.name) ? "" : "Name is required",
                source_value: (milestone === null || milestone === void 0 ? void 0 : milestone.source_value) ? "" : "Value is required",
                target_value: (milestone === null || milestone === void 0 ? void 0 : milestone.target_value) ? "" : "Level is required",
                image_url: (milestone === null || milestone === void 0 ? void 0 : milestone.image_url) || milestone.file ? "" : "Image is required",
                colour: (milestone === null || milestone === void 0 ? void 0 : milestone.colour) ? "" : "Color is required",
            }); }),
        };
        dispatch((0, badge_1.setErrors)(errors));
        return (0, errors_1.generateErrorList)(errors);
    };
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errorList, rewardTrackPayload, existingMilestones, uploadMilestones, milestones, error_1, payload;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    errorList = validate();
                    if (errorList === null || errorList === void 0 ? void 0 : errorList.length) {
                        dispatch((0, main_1.setMessageModal)({ title: "Validation Error", messages: __spreadArray(["Please correct the following errors: ", ""], errorList, true) }));
                        return [2 /*return*/];
                    }
                    rewardTrackPayload = __assign(__assign({}, selectedLevelRewardTrack), { reward_track_type: constants_1.RewardTrackTypes.LEVEL_PROGRESSION });
                    existingMilestones = (_a = selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestones) === null || _a === void 0 ? void 0 : _a.filter(function (milestone) { return !milestone.isFileEdited && milestone.file; });
                    uploadMilestones = rewardTrackPayload === null || rewardTrackPayload === void 0 ? void 0 : rewardTrackPayload.milestones.filter(function (milestone) { return milestone.isFileEdited && milestone.file; }).map(function (milestone) { return __awaiter(void 0, void 0, void 0, function () {
                        var response;
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, (0, upload_file_1.upload_file)({
                                        file: milestone.file,
                                        type: "IMAGE",
                                        file_name: milestone.file.name,
                                        description: milestone === null || milestone === void 0 ? void 0 : milestone.name,
                                        category: "milestone_images",
                                    })];
                                case 1:
                                    response = _b.sent();
                                    if (!(response === null || response === void 0 ? void 0 : response.success) && (response === null || response === void 0 ? void 0 : response.content)) {
                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when uploading your image!", messages: [response.message] }));
                                        throw new Error("Upload failed");
                                    }
                                    return [2 /*return*/, __assign(__assign({}, milestone), { image_url: (_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.location })];
                            }
                        });
                    }); });
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, Promise.all(uploadMilestones)];
                case 2:
                    milestones = _d.sent();
                    rewardTrackPayload = __assign(__assign({}, rewardTrackPayload), { milestones: __spreadArray(__spreadArray([], existingMilestones, true), milestones, true) });
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _d.sent();
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when uploading your images. Please ensure they're images and try again.", messages: [String(error_1)] }));
                    return [2 /*return*/];
                case 4:
                    if (!(reward_track_id && (selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack._id))) return [3 /*break*/, 6];
                    return [4 /*yield*/, dispatch((0, save_reward_track_thunk_1.save_reward_track_thunk)(rewardTrackPayload))];
                case 5:
                    _d.sent();
                    return [3 /*break*/, 8];
                case 6: return [4 /*yield*/, dispatch((0, save_reward_track_thunk_1.save_reward_track_thunk)(rewardTrackPayload))];
                case 7:
                    payload = (_d.sent()).payload;
                    if ((payload === null || payload === void 0 ? void 0 : payload.success) && ((_b = payload.content) === null || _b === void 0 ? void 0 : _b._id)) {
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.LEVEL_PROGRESSION_DETAILS_ROUTE, "/").concat((_c = payload === null || payload === void 0 ? void 0 : payload.content) === null || _c === void 0 ? void 0 : _c._id));
                    }
                    _d.label = 8;
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var rewardTrackStatisticOptions = (_b = (_a = statistics === null || statistics === void 0 ? void 0 : statistics.PlayerStatistics) === null || _a === void 0 ? void 0 : _a.map(function (statistic) { return ({ label: statistic.StatisticName, value: statistic.StatisticName }); })) !== null && _b !== void 0 ? _b : [];
    var setDefaultValues = (0, react_1.useCallback)(function () {
        var defaultSourceValue = rewardTrackStatisticOptions.find(function (option) { return option.value === "xp"; });
        var defaultTargetValue = rewardTrackStatisticOptions.find(function (option) { return option.value === "level"; });
        onChange({ target: defaultTargetValue === null || defaultTargetValue === void 0 ? void 0 : defaultTargetValue.value, source: defaultSourceValue === null || defaultSourceValue === void 0 ? void 0 : defaultSourceValue.value });
    }, [statistics === null || statistics === void 0 ? void 0 : statistics.PlayerStatistics]);
    (0, react_1.useEffect)(function () {
        setDefaultValues();
    }, [statistics === null || statistics === void 0 ? void 0 : statistics.PlayerStatistics]);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 pb-4 h-full" },
        react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2" },
            react_1.default.createElement("div", { className: "flex flex-row" })),
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-row gap-4 justify-between items-center" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Customize your reward track", textColor: "text-back", size: "large", bold: true }),
            react_1.default.createElement("div", { className: "grid" },
                react_1.default.createElement("div", { className: "grow min-w-[125px]" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: onSave, loading: isLoading || statIsLoading, enabled: isEdited })))),
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col h-full overflow-auto gap-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Reward Track Details", textColor: "text-primary", size: "large", bold: true }),
            react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-6" },
                react_1.default.createElement("div", { key: "1", className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Reward Track Information", textColor: "text-primary", bold: true, className: "bg-blue-50 p-1 truncate" }),
                    react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-3 gap-4" },
                        react_1.default.createElement("div", { className: "grid gap-2" },
                            react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Name", textColor: "text-primary", required: true }),
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.name, onChange: function (name) {
                                    onChange({ name: name });
                                }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", errorText: errors === null || errors === void 0 ? void 0 : errors.name })),
                        react_1.default.createElement("div", { className: "grid gap-2" },
                            react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Start Date", textColor: "text-primary" }),
                            react_1.default.createElement(doshx_controls_web_1.DateTimeControl, { value: selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.start_date, onChange: function (start_date) {
                                    onChange({ start_date: start_date });
                                }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText" })),
                        react_1.default.createElement("div", { className: "grid gap-2" },
                            react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "End Date", textColor: "text-primary" }),
                            react_1.default.createElement(doshx_controls_web_1.DateTimeControl, { value: selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.end_date, onChange: function (end_date) {
                                    onChange({ end_date: end_date });
                                }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText" })))),
                react_1.default.createElement("div", { key: "2", className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Configure Year-End Rules", textColor: "text-primary", bold: true, className: "bg-blue-50 p-1" }),
                    react_1.default.createElement("div", { className: "gap-4" },
                        react_1.default.createElement("div", { className: "grid gap-2" },
                            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Reset Points Annually", value: selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.reset_points_annually, onChange: function (reset_points_annually) {
                                    onChange({ reset_points_annually: reset_points_annually });
                                }, textColor: "text-primary" }),
                            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Persist Tier Levels Year-over-Year", value: selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.persist_tier_levels_annually, onChange: function (persist_tier_levels_annually) {
                                    onChange({ persist_tier_levels_annually: persist_tier_levels_annually });
                                }, textColor: "text-primary" })))),
                react_1.default.createElement("div", { key: "3", className: "flex flex-col gap-4 col-span-2" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Milestones Configuration", textColor: "text-primary", bold: true, className: "bg-blue-50 p-1" }),
                    react_1.default.createElement("div", { className: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4" },
                        react_1.default.createElement("div", { className: "grid gap-2" },
                            react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Number of Milestones", textColor: "text-primary", required: true }),
                            react_1.default.createElement(doshx_controls_web_1.IncrementControl, { value: selectedLevelRewardTrack === null || selectedLevelRewardTrack === void 0 ? void 0 : selectedLevelRewardTrack.milestone_count, onChange: function (milestone_count) {
                                    if (milestone_count === 0) {
                                        return dispatch((0, reward_track_1.setSelectedLevelRewardTrack)(__assign(__assign({}, selectedLevelRewardTrack), { milestone_count: 1, milestones: reward_track_1.initialMilestones })));
                                    }
                                    onChange({ milestone_count: milestone_count });
                                }, size: "small", borderColor: "border-blue-300 max-h-10", textColor: "text-inputText", min: 0, max: 10 })),
                        react_1.default.createElement("div", { className: "grid gap-2" },
                            react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Statistic (Source)", textColor: "text-primary", required: true }),
                            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "", options: rewardTrackStatisticOptions, value: selectedLevelRewardTrack.source, required: true, size: "small", disabled: true, borderColor: "border-blue-300", textColor: "text-inputText", errorTextColor: "text-red-500", onChange: function (source) {
                                    onChange({ source: source });
                                }, errorText: errors === null || errors === void 0 ? void 0 : errors.source })),
                        react_1.default.createElement("div", { className: "grid gap-2" },
                            react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Statistic (target)", textColor: "text-primary", required: true }),
                            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "", options: rewardTrackStatisticOptions, value: selectedLevelRewardTrack.target, required: true, disabled: true, size: "small", borderColor: "border-blue-300", textColor: "text-inputText", errorTextColor: "text-red-500", onChange: function (target) {
                                    onChange({ target: target });
                                }, errorText: errors === null || errors === void 0 ? void 0 : errors.target })))), (_c = selectedLevelRewardTrack.milestones) === null || _c === void 0 ? void 0 :
                _c.map(function (milestone, index) {
                    var _a;
                    return (react_1.default.createElement(level_progression_milestone_1.LevelProgressionMilestone, { key: milestone.index, index: index, milestone: milestone, onChange: function (value) {
                            dispatch((0, reward_track_1.setLevelMilestone)(__assign(__assign(__assign({}, milestone), value), { index: index })));
                            setIsEdited(true);
                        }, errors: (_a = errors === null || errors === void 0 ? void 0 : errors.milestones) === null || _a === void 0 ? void 0 : _a[index] }));
                })))));
};
exports.LevelProgressionPage = LevelProgressionPage;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientDetailsPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../constants/routes");
var entry_1 = require("../../../../../reducers/slices/entry");
var user_1 = require("../../../../../reducers/slices/user/user");
var main_1 = require("../../../../../reducers/slices/main");
var terms_and_conditions_1 = require("../../../../terms_and_conditions");
var ClientDetailsPage = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var entryTheme = (0, react_redux_1.useSelector)(function (state) { return state.entryState; }).entryTheme;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _b.user, isLoading = _b.isLoading;
    var _c = (0, react_1.useState)(""), nameMessage = _c[0], setNameMessage = _c[1];
    var _d = (0, react_1.useState)(""), surnameMessage = _d[0], setSurnameMessage = _d[1];
    var _e = (0, react_1.useState)(""), emailMessage = _e[0], setEmailMessage = _e[1];
    var _f = (0, react_1.useState)(""), phoneMessage = _f[0], setPhoneMessage = _f[1];
    var _g = (0, react_1.useState)(""), passwordMessage = _g[0], setPasswordMessage = _g[1];
    var _h = (0, react_1.useState)(""), confirmPasswordMessage = _h[0], setConfirmPasswordMessage = _h[1];
    var _j = (0, react_1.useState)(""), birthdayMessage = _j[0], setBirthdayMessage = _j[1];
    var _k = (0, react_1.useState)(""), agreesMessage = _k[0], setAgreesMessage = _k[1];
    (0, react_1.useEffect)(function () {
        //
    }, []);
    var onControlChanged = function (name, value) {
        var _a;
        console.log(name, value);
        dispatch((0, user_1.setUser)(__assign(__assign({}, user), (_a = {}, _a[name] = value, _a))));
    };
    var validate = function () {
        var messageCount = 0;
        setNameMessage("");
        setSurnameMessage("");
        setEmailMessage("");
        setPhoneMessage("");
        setPasswordMessage("");
        setConfirmPasswordMessage("");
        // setBirthdayMessage("");
        setAgreesMessage("");
        if (!user.name) {
            messageCount++;
            setNameMessage("Name is required.");
        }
        if (!user.surname) {
            messageCount++;
            setSurnameMessage("Surname is required.");
        }
        if (!user.email) {
            messageCount++;
            setEmailMessage("Email is required.");
        }
        if (!user.cell_number) {
            messageCount++;
            setPhoneMessage("Phone Number is required.");
        }
        if (!user.password) {
            messageCount++;
            setPasswordMessage("Password is required.");
        }
        if (!user.confirm_password) {
            messageCount++;
            setConfirmPasswordMessage("Confirm Password is required.");
        }
        else {
            if (user.password != user.confirm_password) {
                messageCount++;
                setConfirmPasswordMessage("Passwords don't match.");
            }
        }
        if (!user.birthday) {
            messageCount++;
            setBirthdayMessage("Birthday is required.");
        }
        if (!user.agrees_terms_and_conditions) {
            messageCount++;
            setAgreesMessage("Must agree to our terms and conditions.");
        }
        return messageCount ? false : true;
    };
    var _openTermsModal = function () {
        dispatch((0, main_1.setContentModal)({ title: "Terms and conditions", content: react_1.default.createElement(terms_and_conditions_1.TermsAndConditionsPage, null) }));
    };
    return (react_1.default.createElement("div", { className: "w-full lg:w-[700px] pt-4" },
        react_1.default.createElement("div", { className: "flex flex-col gap-y-4" },
            react_1.default.createElement("div", { className: "flex flex-col md:flex-row items-start md:items-center" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "flex-1", textColor: "text-black", size: "2xlarge", label: "Create your account", bold: true }),
                react_1.default.createElement("div", { className: "flex gap-1 items-center justify-center" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Already have an account?", textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Sign In", textColor: entryTheme.config.colors.link.text, onClick: function () {
                            navigate("".concat(routes_1.WELCOME_ROUTE).concat(routes_1.LOG_IN_ROUTE));
                        } }))),
            react_1.default.createElement("div", { className: "w-10" },
                react_1.default.createElement(doshx_controls_web_1.DividerControl, { orientation: "horizontal", lineColor: "bg-black", addPadding: false })),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "medium", label: "Sign up using your email address", className: "font-light" }),
            react_1.default.createElement("div", { className: "gap-4 grid grid-cols-2" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", borderColor: "border-borders", required: true, value: user.name, errorText: nameMessage, onChange: function (v) { return onControlChanged("name", v); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", borderColor: "border-borders", required: true, value: user.surname, errorText: surnameMessage, onChange: function (v) { return onControlChanged("surname", v); } })),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email", borderColor: "border-borders", required: true, value: user.email, errorText: emailMessage, onChange: function (v) { return onControlChanged("email", v); } }),
            react_1.default.createElement("div", { className: "gap-4 grid grid-cols-2" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Password", borderColor: "border-borders", value: user.password, required: true, errorText: passwordMessage, type: "password", onChange: function (v) { return onControlChanged("password", v); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Confirm Password", borderColor: "border-borders", required: true, value: user.confirm_password, errorText: confirmPasswordMessage, type: "password", onChange: function (v) { return onControlChanged("confirm_password", v); } })),
            react_1.default.createElement("div", { className: "gap-4 grid grid-cols-2" },
                react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Phone Number", borderColor: "border-borders", required: true, value: user.cell_number, errorText: phoneMessage, onChange: function (v) { return onControlChanged("cell_number", v); } }),
                react_1.default.createElement(doshx_controls_web_1.DatePickerControl, { label: "Birthday", borderColor: "border-borders", required: true, inputFormat: "DD/MM/YYYY", errorText: birthdayMessage, value: (_a = new Date(user.birthday)) !== null && _a !== void 0 ? _a : null, onChange: function (v) { return onControlChanged("birthday", v.toString()); } }))),
        react_1.default.createElement("div", { className: "pt-4" },
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Receive Communication?", value: user.get_communication, uncheckedColor: entryTheme.config.colors.checkbox.box, onChange: function (v) {
                    onControlChanged("get_communication", v);
                } }),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "I have read and agree to the Terms of Service", value: user.agrees_terms_and_conditions, uncheckedColor: entryTheme.config.colors.checkbox.box, errorText: agreesMessage, onChange: function (v) {
                    v && _openTermsModal();
                    onControlChanged("agrees_terms_and_conditions", v);
                } })),
        react_1.default.createElement("div", { className: "w-[200px] pt-4" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Next", backgroundColor: entryTheme.config.colors.buttons.bgPrimary, loading: isLoading, onClick: function () {
                    if (validate()) {
                        // dispatch(sign_up_thunk());
                        dispatch((0, entry_1.setCurrentStep)(1));
                    }
                } }))));
};
exports.ClientDetailsPage = ClientDetailsPage;

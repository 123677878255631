"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setBasketBranch = exports.setBasketFilterdByTotal = exports.setBasketStatus = exports.setBasketType = exports.setBasketFilterRange = exports.setBasketTablePagination = exports.setCurrentParcelStep = exports.setBaskets = exports.setBasket = exports.setIsLoadingBasket = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    baskets: [],
    basket: undefined,
    isLoadingBasket: false,
    basketPagination: pages_1.initialPaginationValues,
    basketFilterRange: {
        fromDate: "",
        toDate: "",
    },
    basketType: "BOTH",
    basketStatus: "ALL",
    basketFilterdByTotal: {
        minValue: "",
        maxValue: "",
    },
    branchId: "",
};
var slice = (0, toolkit_1.createSlice)({
    name: "basket",
    initialState: initialState,
    reducers: {
        setBaskets: function (state, action) {
            state.baskets = action.payload;
        },
        setBasket: function (state, action) {
            state.basket = action.payload;
        },
        setIsLoadingBasket: function (state, action) {
            state.isLoadingBasket = action.payload;
        },
        setCurrentParcelStep: function (state, action) {
            state.currentStep = action.payload;
        },
        setBasketTablePagination: function (state, action) {
            state.basketPagination = action.payload;
        },
        setBasketFilterRange: function (state, action) {
            state.basketFilterRange = action.payload;
        },
        setBasketType: function (state, action) {
            state.basketType = action.payload;
        },
        setBasketStatus: function (state, action) {
            state.basketStatus = action.payload;
        },
        setBasketFilterdByTotal: function (state, action) {
            state.basketFilterdByTotal = action.payload;
        },
        setBasketBranch: function (state, action) {
            state.branchId = action.payload;
        },
    },
});
exports.setIsLoadingBasket = (_a = slice.actions, _a.setIsLoadingBasket), exports.setBasket = _a.setBasket, exports.setBaskets = _a.setBaskets, exports.setCurrentParcelStep = _a.setCurrentParcelStep, exports.setBasketTablePagination = _a.setBasketTablePagination, exports.setBasketFilterRange = _a.setBasketFilterRange, exports.setBasketType = _a.setBasketType, exports.setBasketStatus = _a.setBasketStatus, exports.setBasketFilterdByTotal = _a.setBasketFilterdByTotal, exports.setBasketBranch = _a.setBasketBranch;
exports.default = slice.reducer;
// alex@doshex.com
// 123456 - has baskets

"use strict";
// NB: Start all routes must follow:
// - forward slash at the beginning
// - lowercase words
// - using dashes(hyphens) for multiple words
Object.defineProperty(exports, "__esModule", { value: true });
exports.ORDER_ROUTE = exports.ORDERS_ROUTE = exports.VIEW_BASKET_ROUTE = exports.BASKETS_ROUTE = exports.ECO_STATISTIC_CASHBACK_ROUTE = exports.ECO_CURRENCY_CASHBACK_ROUTE = exports.ECO_WALLET_ROUTE = exports.LEVEL_PROGRESSION_DETAILS_ROUTE = exports.TIER_PROGRESSION_DETAILS_ROUTE = exports.REWARD_TRACK_DETAILS_ROUTE = exports.REWARD_TRACKS_ROUTE = exports.CHALLENGE_DETAILS_ROUTE = exports.CHALLENGES_ROUTE = exports.BADGE_DETAILS_ROUTE = exports.BADGES_ROUTE = exports.BILLING_ROUTE = exports.SETTINGS_ROUTE = exports.PROFILE_ROUTE = exports.USER_ROUTE = exports.USERS_ROUTE = exports.PACKAGES_ROUTE = exports.SELECT_SUBSCRIPTION_ROUTE = exports.SUBSCRIPTIONS_DETAILS_ROUTE = exports.SUBSCRIPTION_ROUTE = exports.SUBSCRIPTIONS_ROUTE = exports.CUSTOMER_PROFILE_ROUTE = exports.CUSTOMERS_ROUTE = exports.DASHBOARD_ROUTE = exports.CONSOLE_ROUTE = exports.PRIVACY_POLICY_ROUTE = exports.TERMS_AND_CONDITIONS_ROUTE = exports.RESET_PASSWORD_ROUTE = exports.FORGOT_PASSWORD_ROUTE = exports.CREATE_CLIENT_ROUTE = exports.SIGN_UP_ROUTE = exports.LOG_IN_ROUTE = exports.WELCOME_ROUTE = void 0;
// ENTRY ROUTES >
exports.WELCOME_ROUTE = "/welcome";
exports.LOG_IN_ROUTE = "/log-in";
exports.SIGN_UP_ROUTE = "/sign-up";
exports.CREATE_CLIENT_ROUTE = "/create-client";
exports.FORGOT_PASSWORD_ROUTE = "/forgot-password";
exports.RESET_PASSWORD_ROUTE = "/reset-password";
exports.TERMS_AND_CONDITIONS_ROUTE = "/terms-and-conditions";
exports.PRIVACY_POLICY_ROUTE = "/privacy-policy";
// CONSOLE ROUTES >
exports.CONSOLE_ROUTE = "/console";
exports.DASHBOARD_ROUTE = "/dashboard";
exports.CUSTOMERS_ROUTE = "/customers";
exports.CUSTOMER_PROFILE_ROUTE = "/customer-profile";
exports.SUBSCRIPTIONS_ROUTE = "/subscriptions";
exports.SUBSCRIPTION_ROUTE = "/create-subscription";
exports.SUBSCRIPTIONS_DETAILS_ROUTE = "/subscription-details";
exports.SELECT_SUBSCRIPTION_ROUTE = "/select-subscription";
exports.PACKAGES_ROUTE = "/packages";
exports.USERS_ROUTE = "/users";
exports.USER_ROUTE = "/user";
exports.PROFILE_ROUTE = "/profile";
exports.SETTINGS_ROUTE = "/settings";
exports.BILLING_ROUTE = "/billings";
exports.BADGES_ROUTE = "/badges";
exports.BADGE_DETAILS_ROUTE = "/badge-details";
exports.CHALLENGES_ROUTE = "/challenges";
exports.CHALLENGE_DETAILS_ROUTE = "/challenge-details";
exports.REWARD_TRACKS_ROUTE = "/reward-tracks";
exports.REWARD_TRACK_DETAILS_ROUTE = "/reward-track-details";
exports.TIER_PROGRESSION_DETAILS_ROUTE = "/tier-progression-details";
exports.LEVEL_PROGRESSION_DETAILS_ROUTE = "/level-progression-details";
exports.ECO_WALLET_ROUTE = "/economics/wallet";
exports.ECO_CURRENCY_CASHBACK_ROUTE = "/economics/currency-cashback";
exports.ECO_STATISTIC_CASHBACK_ROUTE = "/economics/statistic-cashback";
exports.BASKETS_ROUTE = "/baskets";
exports.VIEW_BASKET_ROUTE = "/view-baskets";
exports.ORDERS_ROUTE = "/orders";
exports.ORDER_ROUTE = "/order";

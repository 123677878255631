"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TierProgressionMilestone = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var colourPickerComponent_1 = require("../../../../../components/colourPickerComponent");
var inputLabelComponent_1 = require("../../../../../components/inputLabelComponent");
require("reactflow/dist/base.css");
var TierProgressionMilestone = function (_a) {
    var _b;
    var milestone = _a.milestone, onChange = _a.onChange, _c = _a.errors, errors = _c === void 0 ? {} : _c, index = _a.index;
    var indexValue = String(index + 1);
    var handleOnChange = function (value) {
        onChange(__assign(__assign({}, milestone), value));
    };
    // load file from url if url and create file
    (0, react_1.useEffect)(function () {
        if (milestone.image_url) {
            fetch(milestone.image_url)
                .then(function (res) { return res.blob(); })
                .then(function (blob) {
                var file = new File([blob], milestone.image_url, { type: "image/png" });
                handleOnChange({ file: file });
            });
        }
    }, [milestone.image_url]);
    (0, react_1.useEffect)(function () {
        handleOnChange({ target_value: indexValue });
    }, []);
    var targerValueOptions = (_b = ["1", "2", "3", "4", "5"].map(function (option) { return ({ label: option, value: option }); })) !== null && _b !== void 0 ? _b : [];
    return (react_1.default.createElement("div", { key: index, className: "flex flex-col gap-4 col-span-2 max-w-full" },
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Milestone ".concat(index + 1), textColor: "text-primary", bold: true, className: "bg-blue-50 p-1" }),
        react_1.default.createElement("div", { className: "grid grid-cols-2 lg:grid-cols-4 gap-4" },
            react_1.default.createElement("div", { className: "grid row-span-2 gap-4" },
                react_1.default.createElement("div", { className: "grid gap-2" },
                    react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Name", textColor: "text-primary", required: true }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: milestone.name, onChange: function (name) {
                            handleOnChange({ name: name });
                        }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", errorText: errors === null || errors === void 0 ? void 0 : errors.name })),
                react_1.default.createElement("div", { className: "grid gap-2" },
                    react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Colour", textColor: "text-primary", required: true }),
                    react_1.default.createElement(colourPickerComponent_1.ColourPicker, { label: "", value: milestone.colour, onChange: function (colour) { return handleOnChange({ colour: colour }); }, className: "border border-blue-300 rounded-md p-1" }))),
            react_1.default.createElement("div", { className: "grid gap-2" },
                react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Value", textColor: "text-primary", required: true }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: milestone.source_value, onChange: function (source_value) {
                        handleOnChange({ source_value: source_value });
                    }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", type: "number", errorText: errors === null || errors === void 0 ? void 0 : errors.source_value })),
            react_1.default.createElement("div", { className: "grid gap-2" },
                react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Tier", textColor: "text-primary", required: true }),
                react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "", options: targerValueOptions, value: indexValue, disabled: true, required: true, size: "small", borderColor: "border-blue-300", textColor: "text-inputText", errorTextColor: "text-red-500", onChange: function (target_value) {
                        handleOnChange({ target_value: target_value });
                    }, errorText: errors === null || errors === void 0 ? void 0 : errors.target_value })),
            react_1.default.createElement("div", { className: "grid row-span-2 gap-2" },
                react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Image", textColor: "text-primary", required: true }),
                react_1.default.createElement("div", { className: "grid gap-1" },
                    react_1.default.createElement(doshx_controls_web_1.FileInputBoxControl, { previewFile: true, file: milestone.file, onChange: function (file) {
                            handleOnChange({ file: file, isFileEdited: true });
                        }, required: true, errorText: (errors === null || errors === void 0 ? void 0 : errors.file) || (errors === null || errors === void 0 ? void 0 : errors.image_url), className: "max-h-36 overflow-hidden" }),
                    (errors === null || errors === void 0 ? void 0 : errors.image_url) && react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: errors === null || errors === void 0 ? void 0 : errors.image_url, textColor: "text-red-500", size: "xsmall", className: "pl-4" }))))));
};
exports.TierProgressionMilestone = TierProgressionMilestone;

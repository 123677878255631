"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPageTitle = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    pageTitle: "Not Set",
};
var slice = (0, toolkit_1.createSlice)({
    name: "console",
    initialState: initialState,
    reducers: {
        setPageTitle: function (state, action) {
            state.pageTitle = action.payload;
        },
    },
});
exports.setPageTitle = slice.actions.setPageTitle;
exports.default = slice.reducer;

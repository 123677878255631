"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validationConfig = exports.MULTI_RADII = exports.BASE_RADIUS = exports.FLAT_FEE = exports.deliveryHoursColumns = exports.initialAddressData = exports.initialBankingDetailsData = exports.initialDeliverySettingsValues = exports.initialExclusionValues = exports.initialExtraDataValues = exports.initialBranchTradingTimesValues = exports.initialTradingTimesValues = void 0;
exports.initialTradingTimesValues = {
    day: "",
    from: new Date().toISOString(),
    to: new Date().toISOString(),
};
exports.initialBranchTradingTimesValues = {
    day: "",
    closed: false,
    status: "Open",
    from: "06:00",
    to: "06:00",
};
exports.initialExtraDataValues = {
    name: "",
    value: "",
};
exports.initialExclusionValues = {
    name: "",
    coordinates: [],
};
exports.initialDeliverySettingsValues = {
    delivery: true,
    delivery_fee_type: "FLAT-FEE",
    max_delivery_radius: "",
    base_delivery_radius: "",
    delivery_fee_per_km: "",
    delivery_fee: "",
    delivery_radii: [],
};
exports.initialBankingDetailsData = {
    account_number: "",
    account_type: "",
    branch_code: "",
    name: "",
};
exports.initialAddressData = {
    full_address: "",
};
exports.deliveryHoursColumns = [
    {
        displayKey: "day",
        label: "Day",
    },
    {
        displayKey: "from",
        label: "Deliveries From",
    },
    {
        displayKey: "to",
        label: "Deliveries To",
    },
];
exports.FLAT_FEE = "FLAT-FEE";
exports.BASE_RADIUS = "BASE-RADIUS";
exports.MULTI_RADII = "MULTI-RADII";
exports.validationConfig = {
    max_delivery_radius: { dependOn: { key: "delivery_fee_type", value: exports.FLAT_FEE } },
    delivery_fee: { dependOn: { key: "delivery_fee_type", value: [exports.FLAT_FEE, exports.BASE_RADIUS] } },
    base_delivery_radius: { dependOn: { key: "delivery_fee_type", value: exports.BASE_RADIUS } },
    delivery_fee_per_km: { dependOn: { key: "delivery_fee_type", value: exports.BASE_RADIUS } },
    delivery_radii: { dependOn: { key: "delivery_fee_type", value: exports.MULTI_RADII } },
};

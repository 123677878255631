"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateCategories = exports.setPagination = exports.setCategories = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../../constants/pages");
var initialState = {
    categories: [],
    loading: false,
    pagination: pages_1.initialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "category",
    initialState: initialState,
    reducers: {
        setCategories: function (state, action) {
            state.categories = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        updateCategories: function (state, action) {
            state.categories = __spreadArray(__spreadArray([], state.categories, true), action.payload, true);
        },
    },
});
exports.setCategories = (_a = slice.actions, _a.setCategories), exports.setPagination = _a.setPagination, exports.updateCategories = _a.updateCategories;
exports.default = slice.reducer;

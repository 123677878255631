"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.radioButtonColorConfig = exports.tableColorConfig = exports.initialSortValues = exports.dealsCategoriesInitialPaginationValues = exports.vouchersBranchesInitialPaginationValues = exports.dealsBranchesInitialPaginationValues = exports.filteredClientdealBranchesInitialPaginationValues = exports.filteredClientdealProductsInitialPaginationValues = exports.dealsInitialPaginationValues = exports.comboInitialPaginationValues = exports.initialPaginationValues = void 0;
exports.initialPaginationValues = { page: 1, size: 10, countTotal: 0 };
exports.comboInitialPaginationValues = { page: 1, size: 5, countTotal: 0 };
exports.dealsInitialPaginationValues = { page: 1, size: 5, countTotal: 0 };
exports.filteredClientdealProductsInitialPaginationValues = { page: 1, size: 5, countTotal: 0 };
exports.filteredClientdealBranchesInitialPaginationValues = { page: 1, size: 5, countTotal: 0 };
exports.dealsBranchesInitialPaginationValues = { page: 1, size: 5, countTotal: 0 };
exports.vouchersBranchesInitialPaginationValues = { page: 1, size: 5, countTotal: 0 };
exports.dealsCategoriesInitialPaginationValues = { page: 1, size: 5, countTotal: 0 };
exports.initialSortValues = {
    key: "",
    direction: "desc",
};
exports.tableColorConfig = {
    titleBackgroundColor: "bg-tableBackgroundColor",
    headerBackgroundColor: "bg-primary",
    headerTextColor: "text-white",
    footerBackgroundColor: "bg-tableBackgroundColor",
    hoverRowColor: "hover:bg-borders",
    borderColor: "border-transparent",
    dividerColor: "border-borders",
    oddRowColor: "bg-tableBackgroundColor",
    evenRowColor: "bg-tableBackgroundColor",
    ellipsisBackgroundColor: "bg-transaparent",
    ellipsisIconColor: "text-tableTextColor",
    footerTextColor: "text-tableTextColor",
    searchBackgroundColor: "bg-tableBackgroundColor",
    searchIconColor: "text-tableTextColor",
    searchBorderColor: "border-borders",
    searchTextColor: "text-tableTextColor",
    titleColor: "text-tableTextColor",
    pagination: {
        unselected: {
            borderColor: "border-borders",
            backgroundColor: "bg-tableBackgroundColor",
            textColor: "text-tableTextColor",
        },
        selected: {
            borderColor: "border-borders",
            backgroundColor: "bg-primary",
            textColor: "text-white",
        },
        rowsPerPage: {
            backgroundColor: "bg-white",
            textColor: "text-black",
            borderColor: "border-borders",
        },
    },
};
exports.radioButtonColorConfig = {
    titleColor: "text-black font-normal",
    error: { textColor: "text-red-500" },
    radio: {
        textColor: "text-black-500",
        iconColor: "text-black-500",
    },
    tooltip: {
        popover: {
            backgroundColor: "bg-black",
            textColor: "text-white",
        },
        icon: {
            backgroundColor: "bg-blue-200",
            iconColor: "text-blue-600",
            hoverBackgroundColor: "hover:bg-blue-500",
            hoverIconColor: "hover:text-white",
        },
    },
};

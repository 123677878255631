"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialLoyaltyValues = exports.tableColumns = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
exports.tableColumns = [
    {
        displayKey: "loyalty_number",
        label: "Loyalty Number",
        formatter: function (_) { var _a; return (_a = _ === null || _ === void 0 ? void 0 : _.card) === null || _a === void 0 ? void 0 : _a.number; },
    },
    {
        displayKey: "loyalty_number",
        label: "Loyalty Card Number",
    },
    {
        displayKey: "linked",
        label: "Card Linked",
        formatter: function (_) { var _a; return (((_a = _ === null || _ === void 0 ? void 0 : _.card) === null || _a === void 0 ? void 0 : _a.linked) ? "Yes" : "No"); },
    },
    {
        displayKey: "member_date",
        label: "Date Activated",
        formatter: function (_) { return doshx_controls_web_1.Utilities.formatDateTime(_ === null || _ === void 0 ? void 0 : _.member_date); },
    },
    {
        displayKey: "customer",
        label: "Customer",
        formatter: function (_) { var _a, _b; return "".concat((_a = _ === null || _ === void 0 ? void 0 : _.user) === null || _a === void 0 ? void 0 : _a.name, " ").concat((_b = _ === null || _ === void 0 ? void 0 : _.user) === null || _b === void 0 ? void 0 : _b.surname); },
    },
];
exports.initialLoyaltyValues = {
    member_date: doshx_controls_web_1.Utilities.formatDateTime(new Date(), "YYYY-MM-DD"),
    loyalty_number: "",
    card: {
        number: "",
        linked: false,
    },
};

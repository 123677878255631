"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSubscriptionProductsPagination = exports.setSubscriptionUserRedemptionPagination = exports.setUserSubscriptionPagination = exports.setSubscriptionUserRedemption = exports.setSubscriptionProducts = exports.setUserSubscriptions = exports.setSelectedSubscription = exports.setSubscriptions = exports.setIsLoading = exports.setTablePagination = exports.setSubscriptionsAnalytics = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var constants_1 = require("../../../screens/console/pages/configurations/subscriptions/constants");
var _devMode = process.env.NODE_ENV !== "production";
var initialState = {
    subscriptions: [],
    userSubscriptions: [],
    subscriptionProducts: [],
    subscriptionUserRedemption: [],
    subscriptionsAnalytics: {
        subscribers: _devMode ? 10 : 0,
        redemptions: _devMode ? 10 : 0,
        subscriptionsValue: _devMode ? 10 : 0,
        mostPurchasedProduct: _devMode ? 10 : 0,
        incrementalRevenueDate: _devMode ? "1/02/2023" : "",
    },
    selectedSubscription: constants_1.initialSubscriptionValues,
    pagination: pages_1.initialPaginationValues,
    userSubscriptionPagination: pages_1.initialPaginationValues,
    subscriptionUserRedemptionPagination: pages_1.initialPaginationValues,
    subscriptionProductsPagination: pages_1.initialPaginationValues,
    isLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "subscriptions",
    initialState: initialState,
    reducers: {
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setUserSubscriptions: function (state, action) {
            state.userSubscriptions = action.payload;
        },
        setSubscriptionProducts: function (state, action) {
            state.subscriptionProducts = action.payload;
        },
        setSubscriptionUserRedemption: function (state, action) {
            state.subscriptionUserRedemption = action.payload;
        },
        setSubscriptionsAnalytics: function (state, action) {
            state.subscriptionsAnalytics = action.payload;
        },
        setSubscriptions: function (state, action) {
            state.subscriptions = action.payload;
        },
        setSelectedSubscription: function (state, action) {
            state.selectedSubscription = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
        setUserSubscriptionPagination: function (state, action) {
            state.userSubscriptionPagination = action.payload;
        },
        setSubscriptionUserRedemptionPagination: function (state, action) {
            state.subscriptionUserRedemptionPagination = action.payload;
        },
        setSubscriptionProductsPagination: function (state, action) {
            state.subscriptionProductsPagination = action.payload;
        },
    },
});
exports.setSubscriptionsAnalytics = (_a = slice.actions, _a.setSubscriptionsAnalytics), exports.setTablePagination = _a.setTablePagination, exports.setIsLoading = _a.setIsLoading, exports.setSubscriptions = _a.setSubscriptions, exports.setSelectedSubscription = _a.setSelectedSubscription, exports.setUserSubscriptions = _a.setUserSubscriptions, exports.setSubscriptionProducts = _a.setSubscriptionProducts, exports.setSubscriptionUserRedemption = _a.setSubscriptionUserRedemption, exports.setUserSubscriptionPagination = _a.setUserSubscriptionPagination, exports.setSubscriptionUserRedemptionPagination = _a.setSubscriptionUserRedemptionPagination, exports.setSubscriptionProductsPagination = _a.setSubscriptionProductsPagination;
exports.default = slice.reducer;

"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDealBranchPagination = exports.setClientFilteredDealBranchesPagination = exports.setClientFilteredDealBranches = exports.setDealBranchList = exports.setDashboardBranchPagination = exports.setDashboardBranchList = exports.setTablePagination = exports.setIsLoadingBranch = exports.setBranchList = exports.setSelectedBranch = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var constants_1 = require("../../../screens/console/pages/users/branches/constants");
var initialState = {
    branches: [],
    dashboardBranches: [],
    dealBranches: [],
    clientFilteredDealBranches: [],
    selectedBranch: constants_1.initialBranchData,
    isLoading: false,
    pagination: pages_1.initialPaginationValues,
    dashboardBranchPagination: pages_1.initialPaginationValues,
    dealBranchPagination: pages_1.dealsBranchesInitialPaginationValues,
    clientFilteredDealBranchesPagination: pages_1.filteredClientdealBranchesInitialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "branch",
    initialState: initialState,
    reducers: {
        setSelectedBranch: function (state, action) {
            state.selectedBranch = action.payload;
        },
        setBranchList: function (state, action) {
            state.branches = action.payload;
        },
        setIsLoadingBranch: function (state, action) {
            state.isLoading = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
        setDashboardBranchList: function (state, action) {
            state.dashboardBranches = action.payload;
        },
        setDashboardBranchPagination: function (state, action) {
            state.dashboardBranchPagination = action.payload;
        },
        setDealBranchList: function (state, action) {
            state.dealBranches = action.payload;
        },
        setClientFilteredDealBranches: function (state, action) {
            state.clientFilteredDealBranches = action.payload;
        },
        setClientFilteredDealBranchesPagination: function (state, action) {
            state.clientFilteredDealBranchesPagination = action.payload;
        },
        setDealBranchPagination: function (state, action) {
            state.dealBranchPagination = action.payload;
        },
    },
});
exports.setSelectedBranch = (_a = slice.actions, _a.setSelectedBranch), exports.setBranchList = _a.setBranchList, exports.setIsLoadingBranch = _a.setIsLoadingBranch, exports.setTablePagination = _a.setTablePagination, exports.setDashboardBranchList = _a.setDashboardBranchList, exports.setDashboardBranchPagination = _a.setDashboardBranchPagination, exports.setDealBranchList = _a.setDealBranchList, exports.setClientFilteredDealBranches = _a.setClientFilteredDealBranches, exports.setClientFilteredDealBranchesPagination = _a.setClientFilteredDealBranchesPagination, exports.setDealBranchPagination = _a.setDealBranchPagination;
exports.default = slice.reducer;

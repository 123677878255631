"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RewardTrackTypes = exports.SubscriptionPlans = exports.DATE_FORMAT = exports.ADMIN = exports.AREA_MANAGER = exports.BRANCH_MANAGER = exports.CLIENT = exports.BRANCH = exports.UPGRADE_MODAL_TITLE = exports.REQUEST_ERROR_TITLE = exports.PLATFORM = exports.VAT = void 0;
exports.VAT = 0.15;
exports.PLATFORM = "LETSTRADE";
exports.REQUEST_ERROR_TITLE = "Something went wrong!";
exports.UPGRADE_MODAL_TITLE = "Upgrade Required";
exports.BRANCH = "BRANCH";
exports.CLIENT = "CLIENT";
exports.BRANCH_MANAGER = "BRANCH_MANAGER";
exports.AREA_MANAGER = "AREA_MANAGER";
exports.ADMIN = "ADMIN";
exports.DATE_FORMAT = "YYYY-MM-DD";
var SubscriptionPlans;
(function (SubscriptionPlans) {
    SubscriptionPlans["FREEMIUM"] = "FREEMIUM";
    SubscriptionPlans["BUSINESS"] = "BUSINESS";
    SubscriptionPlans["STARTER"] = "STARTER";
    SubscriptionPlans["GROWTH"] = "GROWTH";
})(SubscriptionPlans = exports.SubscriptionPlans || (exports.SubscriptionPlans = {}));
var RewardTrackTypes;
(function (RewardTrackTypes) {
    RewardTrackTypes["TIER_PROGRESSION"] = "TIER_PROGRESSION";
    RewardTrackTypes["LEVEL_PROGRESSION"] = "LEVEL_PROGRESSION";
})(RewardTrackTypes = exports.RewardTrackTypes || (exports.RewardTrackTypes = {}));

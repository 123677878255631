"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCurrentStep = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var configuration_1 = require("../../screens/entry/configuration");
var entryTheme = (0, configuration_1.findDomainConfig)();
var initialState = {
    currentStep: 0,
    entryTheme: entryTheme,
};
var slice = (0, toolkit_1.createSlice)({
    name: "entry",
    initialState: initialState,
    reducers: {
        setCurrentStep: function (state, action) {
            state.currentStep = action.payload;
        },
    },
});
exports.setCurrentStep = slice.actions.setCurrentStep;
exports.default = slice.reducer;

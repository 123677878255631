"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsLoading = exports.setSegments = exports.clearState = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    segments: [],
    isLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "segmentState",
    initialState: initialState,
    reducers: {
        clearState: function (state) {
            state.segments = initialState.segments;
            state.isLoading = initialState.isLoading;
        },
        setSegments: function (state, action) {
            state.segments = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
    },
});
exports.clearState = (_a = slice.actions, _a.clearState), exports.setSegments = _a.setSegments, exports.setIsLoading = _a.setIsLoading;
exports.default = slice.reducer;

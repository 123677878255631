"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTablePagination = exports.setIsLoading = exports.setLoyalties = exports.setLoyalty = exports.setSelectedLoyalty = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var constants_1 = require("../../../screens/console/pages/billing/loyalty/constants");
var initialState = {
    loyalties: [],
    loyalty: {},
    selectedLoyalty: constants_1.initialLoyaltyValues,
    isLoading: false,
    pagination: pages_1.initialPaginationValues,
    sorter: pages_1.initialSortValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "loyalty",
    initialState: initialState,
    reducers: {
        setSelectedLoyalty: function (state, action) {
            state.selectedLoyalty = action.payload;
        },
        setLoyalties: function (state, action) {
            state.loyalties = action.payload;
        },
        setLoyalty: function (state, action) {
            state.loyalty = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
    },
});
exports.setSelectedLoyalty = (_a = slice.actions, _a.setSelectedLoyalty), exports.setLoyalty = _a.setLoyalty, exports.setLoyalties = _a.setLoyalties, exports.setIsLoading = _a.setIsLoading, exports.setTablePagination = _a.setTablePagination;
exports.default = slice.reducer;

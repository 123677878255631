"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setErrors = exports.setComponent = exports.setPagination = exports.setFile = exports.setFileEdited = exports.setIsEdited = exports.setIsLoading = exports.setBadges = exports.clearState = exports.setSelectedBadge = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    selectedBadge: {
        name: "",
        description: "",
        sub_copy: "",
        start_date: null,
        end_date: null,
        dates_enabled: false,
        quantity_limited: false,
        quantity: 0,
        icon: {
            name: "",
            url: "",
            etag: "",
        },
        theme: {
            primary: "#d2a023",
            name: "#d2a023",
            sub_copy: "#000000",
            background: "#FFFFFF",
            icon: "#FFFFFF",
        },
        item_id: "",
    },
    file: null,
    badges: [],
    pagination: pages_1.initialPaginationValues,
    isLoading: false,
    isEdited: false,
    isFileEdited: false,
    component: 0,
    errors: {},
};
var slice = (0, toolkit_1.createSlice)({
    name: "badgeState",
    initialState: initialState,
    reducers: {
        setSelectedBadge: function (state, action) {
            state.selectedBadge = action.payload;
        },
        clearState: function (state) {
            state.selectedBadge = initialState.selectedBadge;
            state.file = initialState.file;
            state.badges = initialState.badges;
            state.pagination = initialState.pagination;
            state.isLoading = initialState.isLoading;
            state.isEdited = initialState.isEdited;
            state.isFileEdited = initialState.isFileEdited;
            state.component = initialState.component;
            state.errors = initialState.errors;
        },
        setBadges: function (state, action) {
            state.badges = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setIsEdited: function (state, action) {
            state.isEdited = action.payload;
        },
        setFileEdited: function (state, action) {
            state.isFileEdited = action.payload;
        },
        setFile: function (state, action) {
            state.file = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setComponent: function (state, action) {
            state.component = action.payload;
        },
        setErrors: function (state, action) {
            state.errors = action.payload;
        },
    },
});
exports.setSelectedBadge = (_a = slice.actions, _a.setSelectedBadge), exports.clearState = _a.clearState, exports.setBadges = _a.setBadges, exports.setIsLoading = _a.setIsLoading, exports.setIsEdited = _a.setIsEdited, exports.setFileEdited = _a.setFileEdited, exports.setFile = _a.setFile, exports.setPagination = _a.setPagination, exports.setComponent = _a.setComponent, exports.setErrors = _a.setErrors;
exports.default = slice.reducer;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainScreen = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_2 = require("react-redux");
var main_1 = require("../reducers/slices/main");
var user_1 = require("../reducers/slices/user/user");
var terms_and_conditions_1 = require("./terms_and_conditions");
var privacy_policy_1 = require("./privacy_policy");
var entry_1 = require("./entry/entry");
var console_1 = require("./console/console");
var routes_1 = require("../constants/routes");
var local_storage_names_1 = require("../constants/local_storage_names");
// import { setHeroConnectToken, setHeroConnectUser } from "../reducers/slices/heroconnect/heroconnect";
// import { TrackParcelPage } from "./entry/pages/track_order/track_parcel";
// import { ParcelCheckPage } from "./entry/pages/track_order/parcel_check";
// import { SuccessBasketPayment } from "./entry/pages/basket/success_basket_payment";
// import { FailedBasketPayment } from "./entry/pages/basket/failed_basket_payment";
// import { BasketPayfastGateway } from "./entry/pages/basket/payfast_gateway";
var MainScreen = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var location = (0, react_router_dom_1.useLocation)();
    var mainRoutes = (0, react_router_dom_1.useRoutes)([
        { index: true, element: react_1.default.createElement(react_router_dom_1.Navigate, { to: routes_1.WELCOME_ROUTE + routes_1.LOG_IN_ROUTE }) },
        { path: "".concat(routes_1.WELCOME_ROUTE, "/*"), element: react_1.default.createElement(entry_1.EntryPage, null) },
        { path: "".concat(routes_1.CONSOLE_ROUTE, "/*"), element: react_1.default.createElement(console_1.ConsolePage, null) },
        { path: "".concat(routes_1.TERMS_AND_CONDITIONS_ROUTE, "/*"), element: react_1.default.createElement(terms_and_conditions_1.TermsAndConditionsPage, null) },
        { path: "".concat(routes_1.PRIVACY_POLICY_ROUTE, "/*"), element: react_1.default.createElement(privacy_policy_1.PrivacyPolicyPage, null) },
        // { path: `${TRACK_ORDER_ROUTE}/:code?`, element: <ParcelCheckPage /> },
        // { path: `${TRACK_PARCEL_ROUTE}/:parcel_id`, element: <TrackParcelPage /> },
        // { path: `${SUCCESS_BASKET_ROUTE}/:basketID/:userID/:paymentIdentifier`, element: <SuccessBasketPayment /> }, //
        // { path: `${FAILED_BASKET_ROUTE}/:basketID/:userID/:paymentIdentifier`, element: <FailedBasketPayment /> },
        // { path: `${BASKET_PAYFAST_GATEWAY_ROUTE}/:basketID/:clientID/:userID/:name/:surname/:cellNumber/:amount/:itemName`, element: <BasketPayfastGateway /> },
        // Remember if you add a route here, you should consider if it must be added below in the useEffect, or not.
    ]);
    var _a = (0, react_redux_2.useSelector)(function (state) { return state.mainState; }), messageModal = _a.messageModal, contentModal = _a.contentModal;
    (0, react_1.useEffect)(function () {
        var userLocalStorage = localStorage.getItem(local_storage_names_1.USER_LOCAL_STORAGE);
        var heroConnectUserLocalStorage = localStorage.getItem(local_storage_names_1.HERO_CONNECT_USER_LOCAL_STORAGE);
        if (heroConnectUserLocalStorage) {
            var _heroConnectUser = JSON.parse(heroConnectUserLocalStorage);
            // dispatch(setHeroConnectUser(_heroConnectUser.user));
            // dispatch(setHeroConnectToken(_heroConnectUser.token));
        }
        if (userLocalStorage) {
            var ls = JSON.parse(userLocalStorage);
            dispatch((0, user_1.setUserToken)(ls.token));
            dispatch((0, user_1.setClientToken)(ls.client_token));
            dispatch((0, user_1.setMediaUploadToken)(ls.media_upload_token));
            dispatch((0, user_1.setPaymentEngineToken)(ls.payment_engine_token));
            dispatch((0, user_1.setUser)(ls.user));
        }
        else {
            // console.log("location.pathname", location.pathname);
            // TODO: What the hell was this `.json` file for??
            //       I'm removing it until it breaks so that we can see what it was for.
            // if (!location.pathname.includes(".json")) {
            // NB: Then here, we must look for the welcome route in the current URL before navigating to it.
            // Only navigate to welcome route if we are not already inside it.
            // check if current pathname is not in main routes
            // const matchedRoutes = matchRoutes(mainRoutes, currentURL);
            // const isMatched = matchedRoutes.length > 0;
            // console.log("isMatched", isMatched);
            if (
            // Exclude these routes from the redirection check.
            !location.pathname.includes(routes_1.WELCOME_ROUTE) &&
                !location.pathname.includes(routes_1.TERMS_AND_CONDITIONS_ROUTE) &&
                !location.pathname.includes(routes_1.PRIVACY_POLICY_ROUTE)) {
                navigate(routes_1.WELCOME_ROUTE);
            }
        }
    }, []);
    return (react_1.default.createElement("div", { className: "h-screen" },
        react_1.default.createElement("div", { className: "h-full" }, 
        // <Routes>
        //   {/* <Route index element={<Navigate to={WELCOME_ROUTE} />} /> */}
        //   <Route path={`${WELCOME_ROUTE}/*`} element={<EntryPage />} />
        //   <Route path={`${CONSOLE_ROUTE}/*`} element={<ConsolePage />} />
        //   <Route path={`${TERMS_AND_CONDITIONS_ROUTE}/*`} element={<TermsAndConditionsPage />} />
        //   <Route path={`${PRIVACY_POLICY_ROUTE}/*`} element={<PrivacyPolicyPage />} />
        //   <Route path={`${TRACK_ORDER_ROUTE}/*`} element={<TrackOrderPage />} />
        // </Routes>
        mainRoutes),
        messageModal && (react_1.default.createElement(doshx_controls_web_1.ModalControl, { title: messageModal.title, open: true, onClose: function () {
                dispatch((0, main_1.setMessageModal)(null));
                messageModal.onClose && messageModal.onClose();
            } },
            react_1.default.createElement("div", { className: "p-4" }, messageModal.messages.map(function (message, index) {
                return react_1.default.createElement("div", { key: "modal-message-".concat(index) }, message);
            })),
            messageModal.buttons && messageModal.buttons.length > 0 && (react_1.default.createElement("div", { className: "flex justify-evenly gap-4 pb-4 px-4" }, messageModal.buttons.map(function (button, index) {
                var _a, _b, _c, _d;
                return (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { key: "modal-message-button-".concat(index), loading: (_a = button.loading) !== null && _a !== void 0 ? _a : false, label: button.label, size: "small", backgroundColor: (_b = button.backgroundColor) !== null && _b !== void 0 ? _b : "bg-primary", textColor: (_c = button.textColor) !== null && _c !== void 0 ? _c : "text-white", borderColor: (_d = button.borderColor) !== null && _d !== void 0 ? _d : "border-primary", onClick: button.onClick }));
            }))))),
        contentModal && (react_1.default.createElement(doshx_controls_web_1.ModalControl, { title: contentModal.title, open: true, onClose: function () {
                dispatch((0, main_1.setContentModal)(null));
            } },
            react_1.default.createElement("div", { className: "p-4" }, contentModal.content)))));
};
exports.MainScreen = MainScreen;

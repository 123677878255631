"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnhancedUserDetails = void 0;
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var stepProgressBar_1 = require("../../../../../controls/stepProgressBar");
var tierLabels = ["Tier 1", "Tier 2", "Tier 3", "Tier 4", "Tier 5"];
var levelLabels = ["Level 1", "Level 2", "Level 3", "Level 4", "Level 5"];
var EnhancedUserDetails = function (_a) {
    var customerId = _a.customerId;
    var tierProgression = [0, 100, 200, 300, 400, 500];
    var levelProgression = [0, 100, 200, 300, 400, 500];
    var _b = (0, react_1.useState)(0), tierProgressionValue = _b[0], setTierProgressionValue = _b[1];
    var _c = (0, react_1.useState)(0), levelProgressionValue = _c[0], setLevelProgressionValue = _c[1];
    var _d = (0, react_1.useState)(0), walletAmount = _d[0], setWalletAmount = _d[1];
    var _e = (0, react_1.useState)(0), challengeParticipated = _e[0], setChallengeParticipated = _e[1];
    var _f = (0, react_1.useState)(0), challengeCompleted = _f[0], setChallengeCompleted = _f[1];
    var _g = (0, react_1.useState)(0), badgesEarned = _g[0], setBadgesEarned = _g[1];
    var maxTierProgression = tierProgression[tierProgression.length - 1];
    var maxLevelProgression = levelProgression[levelProgression.length - 1];
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Customer Profile", size: "small", textColor: "text-inputText", bold: true }),
        react_1.default.createElement("div", { className: "flex flex-row gap-10 mb-8" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4 w-6/12" },
                react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Tier Progression", bold: true, size: "small", textColor: "text-inputText" }),
                    react_1.default.createElement("div", { className: "self-end" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(levelProgressionValue, "/").concat(maxTierProgression), bold: true, size: "xsmall", textColor: "text-inputText" }))),
                react_1.default.createElement(stepProgressBar_1.StepProgressBar, { labels: tierLabels, value: 10 })),
            react_1.default.createElement("div", { className: "flex flex-col gap-4 w-6/12" },
                react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Level Progression", bold: true, size: "small", textColor: "text-inputText" }),
                    react_1.default.createElement("div", { className: "self-end" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(levelProgressionValue, "/").concat(maxLevelProgression), bold: true, size: "xsmall", textColor: "text-inputText" }))),
                react_1.default.createElement(stepProgressBar_1.StepProgressBar, { labels: levelLabels, value: 50 }))),
        react_1.default.createElement("div", { className: "flex flex-row justify-between gap-2" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4 border border-gray-300 rounded-xl p-4 py-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Wallet Balance", size: "small", textColor: "text-inputText" }),
                react_1.default.createElement("div", { className: "flex items-center justify-start gap-2" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { size: "2xl", icon: pro_solid_svg_icons_1.faWallet, className: "text-green-500" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "$200.00", size: "small", textColor: "text-inputText" }))),
            react_1.default.createElement("div", { className: "flex flex-col gap-4 border border-gray-300 rounded-xl p-4 py-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: 'Challenges Participated', size: "small", textColor: "text-inputText" }),
                react_1.default.createElement("div", { className: "flex items-center justify-start gap-2" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { size: "2xl", icon: pro_solid_svg_icons_1.faGamepad, className: "text-blue-500" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "30", size: "small", textColor: "text-inputText" }))),
            react_1.default.createElement("div", { className: "flex flex-col gap-4 border border-gray-300 rounded-xl p-4 py-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: 'Challenges Completed', size: "small", textColor: "text-inputText" }),
                react_1.default.createElement("div", { className: "flex items-center justify-start gap-2" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { size: "2xl", icon: pro_solid_svg_icons_1.faGamepad, className: "text-yellow-500" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "10", size: "small", textColor: "text-inputText" }))),
            react_1.default.createElement("div", { className: "flex flex-col gap-4 border border-gray-300 rounded-xl p-4 py-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: 'Badges Earned', size: "small", textColor: "text-inputText" }),
                react_1.default.createElement("div", { className: "flex items-center justify-start gap-2" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { size: "2xl", icon: pro_solid_svg_icons_1.faBadge, className: "text-orange-500" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "10", size: "small", textColor: "text-inputText" }))))));
};
exports.EnhancedUserDetails = EnhancedUserDetails;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentPlanPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../constants/routes");
var entry_1 = require("../../../../../reducers/slices/entry");
var sign_out_thunk_1 = require("../../../../../reducers/slices/user/thunks/sign_out_thunk");
var PaymentPlanPage = function (_a) {
    var _b = _a.hasBackButton, hasBackButton = _b === void 0 ? true : _b;
    var domain = window.location.host;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var entryTheme = (0, react_redux_1.useSelector)(function (state) { return state.entryState; }).entryTheme;
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _c.user, isLoading = _c.isLoading;
    var integration_settings = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client.integration_settings;
    (0, react_1.useEffect)(function () {
        //
    }, []);
    return (react_1.default.createElement("div", { className: "w-full  lg:w-[1000px] pt-8" },
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "large", label: "Please select your subscription", bold: true }),
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "medium", label: "pick the perfect subscription to your suit your buisness ." }),
        react_1.default.createElement("div", { className: "w-10" },
            react_1.default.createElement(doshx_controls_web_1.DividerControl, { orientation: "horizontal", lineColor: "bg-black", addPadding: true })),
        react_1.default.createElement("stripe-pricing-table", { "pricing-table-id": process.env.STRIPE_PRICING_TABLE_ID, "publishable-key": process.env.STRIPE_PUBLISHABLE_KEY, "client-reference-id": integration_settings === null || integration_settings === void 0 ? void 0 : integration_settings.payment_engine_id, "customer-email": user === null || user === void 0 ? void 0 : user.email }),
        react_1.default.createElement("div", { className: "w-[200px] pt-8" }, hasBackButton ? (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Back", backgroundColor: "bg-black", loading: isLoading, onClick: function () {
                dispatch((0, entry_1.setCurrentStep)(2));
            } })) : (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Log out", backgroundColor: entryTheme.config.colors.buttons.bgPrimary, loading: isLoading, onClick: function () {
                dispatch((0, sign_out_thunk_1.sign_out_thunk)()).then(function () {
                    navigate(routes_1.WELCOME_ROUTE);
                });
            } })))));
};
exports.PaymentPlanPage = PaymentPlanPage;

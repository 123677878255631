"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTablePagination = exports.setIsLoadingCustomer = exports.setCustomer = exports.setIsLoadingCustomers = exports.setCustomers = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var _devMode = process.env.NODE_ENV !== "production";
var initialState = {
    customers: [],
    isLoadingCustomers: false,
    customer: {},
    isLoadingCustomer: false,
    pagination: pages_1.initialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "customers",
    initialState: initialState,
    reducers: {
        setCustomers: function (state, action) {
            state.customers = action.payload;
        },
        setIsLoadingCustomers: function (state, action) {
            state.isLoadingCustomers = action.payload;
        },
        setCustomer: function (state, action) {
            state.customer = action.payload;
        },
        setIsLoadingCustomer: function (state, action) {
            state.isLoadingCustomer = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
    },
});
exports.setCustomers = (_a = slice.actions, _a.setCustomers), exports.setIsLoadingCustomers = _a.setIsLoadingCustomers, exports.setCustomer = _a.setCustomer, exports.setIsLoadingCustomer = _a.setIsLoadingCustomer, exports.setTablePagination = _a.setTablePagination;
exports.default = slice.reducer;

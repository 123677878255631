"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EarnConfigCurrencyPage = void 0;
var react_1 = __importStar(require("react"));
require("reactflow/dist/base.css");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var economy_1 = require("../../../../reducers/slices/economy/economy");
var main_1 = require("../../../../reducers/slices/main");
var pages_1 = require("../../../../constants/pages");
var get_category_thunk_1 = require("../../../../reducers/slices/products/categories/thunks/get_category_thunk");
var get_products_thunk_1 = require("../../../../reducers/slices/products/thunks/get_products_thunk");
var constants_1 = require("./constants");
var console_1 = require("../../../../reducers/slices/console");
var inputLabelComponent_1 = require("../../../../components/inputLabelComponent");
var get_segments_thunk_1 = require("../../../../reducers/slices/segment/thunks/get_segments_thunk");
var errors_1 = require("../../../../helpers/errors");
var get_currencies_thunk_1 = require("../../../../reducers/slices/economy/thunks/get_currencies_thunk");
var save_earn_config_thunk_1 = require("../../../../reducers/slices/economy/thunks/save_earn_config_thunk");
var get_earn_config_thunk_1 = require("../../../../reducers/slices/economy/thunks/get_earn_config_thunk");
var EarnConfigCurrencyPage = function () {
    var _a, _b, _c;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.economyState; }), selectedEarnConfig = _d.selectedEarnConfig, currencies = _d.currencies, economyIsLoading = _d.isLoading, isEdited = _d.isEdited, errors = _d.errors;
    var _e = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), products = _e.products, productPagination = _e.pagination, productsIsLoading = _e.loading;
    var _f = (0, react_redux_1.useSelector)(function (state) { return state.categoryState; }), categories = _f.categories, categoriesPagination = _f.pagination, categoriesIsLoading = _f.loading;
    var _g = (0, react_redux_1.useSelector)(function (state) { return state.segmentState; }), segments = _g.segments, segmentsIsLoading = _g.isLoading;
    var _h = (0, react_1.useState)([]), productOptions = _h[0], setProductOptions = _h[1];
    var _j = (0, react_1.useState)([]), categoryOptions = _j[0], setCategoryOptions = _j[1];
    var _k = (0, react_1.useState)([]), excludedProducts = _k[0], setExcludedProducts = _k[1];
    var _l = (0, react_1.useState)([]), excludedCategories = _l[0], setExcludedCategories = _l[1];
    var isLoading = economyIsLoading || segmentsIsLoading || productsIsLoading || categoriesIsLoading;
    var currencyOptions = (currencies === null || currencies === void 0 ? void 0 : currencies.map(function (currency) { return ({ label: currency.name, value: currency._id }); })) || [];
    var segmentOptions = (segments === null || segments === void 0 ? void 0 : segments.map(function (segment) { return ({ label: segment.name, value: segment.segment_id }); })) || [];
    (0, react_1.useEffect)(function () {
        dispatch((0, get_earn_config_thunk_1.get_earn_config_thunk)(constants_1.EarnConfigType.Currency)).then(function (_a) {
            var payload = _a.payload;
            if (payload === null || payload === void 0 ? void 0 : payload.success) {
                dispatch((0, economy_1.setSelectedEarnConfig)(payload.content));
            }
        });
        dispatch((0, console_1.setPageTitle)("Economics"));
        dispatch((0, get_category_thunk_1.get_category_thunk)({}));
        dispatch((0, get_products_thunk_1.get_products)({}));
        dispatch((0, get_segments_thunk_1.get_segment_list_thunk)({}));
        dispatch((0, get_currencies_thunk_1.get_currencies_thunk)({}));
        return function () {
            dispatch((0, economy_1.clearState)());
        };
    }, []);
    (0, react_1.useEffect)(function () {
        if (!(selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_product_ids))
            return;
        setExcludedProducts(products.filter(function (product) { var _a; return (_a = selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_product_ids) === null || _a === void 0 ? void 0 : _a.includes(product._id); }));
    }, [selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_product_ids]);
    (0, react_1.useEffect)(function () {
        if (!(selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_category_ids))
            return;
        setExcludedCategories(categories.filter(function (category) { var _a; return (_a = selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_category_ids) === null || _a === void 0 ? void 0 : _a.includes(category._id); }));
    }, [selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_category_ids]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (((_a = selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_product_ids) === null || _a === void 0 ? void 0 : _a.length) && !excludedProducts.length) {
            setExcludedProducts(products.filter(function (product) { var _a; return (_a = selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_product_ids) === null || _a === void 0 ? void 0 : _a.includes(product._id); }));
        }
        setProductOptions(products.map(function (product) { return ({ label: product.title, value: product._id }); }));
    }, [products]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (((_a = selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_category_ids) === null || _a === void 0 ? void 0 : _a.length) && !excludedCategories.length) {
            setExcludedCategories(categories.filter(function (category) { var _a; return (_a = selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_category_ids) === null || _a === void 0 ? void 0 : _a.includes(category._id); }));
        }
        setCategoryOptions(categories.map(function (category) { return ({ label: category.name, value: category._id }); }));
    }, [categories]);
    var onChange = function (values) {
        dispatch((0, economy_1.setSelectedEarnConfig)(__assign(__assign({}, selectedEarnConfig), values)));
        if (!isEdited) {
            dispatch((0, economy_1.setIsEdited)(true));
        }
    };
    var validate = function () {
        var errors = {
            trigger_value: (selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.trigger_value) ? "" : "Trigger Value is required",
            earn_amount: (selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.earn_amount) ? "" : "Earn Amount is required",
            segment_id: (selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.segment_id) ? "" : "Segment is required",
        };
        if ((selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.type) === constants_1.EarnConfigType.Currency) {
            errors.earn_target = (selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.earn_target) ? "" : "Currency is required";
        }
        dispatch((0, economy_1.setErrors)(errors));
        return (0, errors_1.generateErrorList)(errors);
    };
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errorList, earn_configPayload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    errorList = validate();
                    if (errorList === null || errorList === void 0 ? void 0 : errorList.length) {
                        dispatch((0, main_1.setMessageModal)({ title: "Validation Error", messages: __spreadArray(["Please correct the following errors: ", ""], errorList, true) }));
                        return [2 /*return*/];
                    }
                    earn_configPayload = __assign({}, selectedEarnConfig);
                    earn_configPayload.type = constants_1.EarnConfigType.Currency;
                    if (earn_configPayload.excluded_category_ids.length === 0) {
                        earn_configPayload.excluded_category_ids = null;
                    }
                    if (earn_configPayload.excluded_product_ids.length === 0) {
                        earn_configPayload.excluded_product_ids = null;
                    }
                    return [4 /*yield*/, dispatch((0, save_earn_config_thunk_1.save_earn_config_thunk)(earn_configPayload))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 pb-4 h-full" },
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-row gap-4 justify-between items-center" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Currency Cashback", textColor: "text-back", size: "large", bold: true }),
            react_1.default.createElement("div", { className: "grid" },
                react_1.default.createElement("div", { className: "grow min-w-[125px]" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: onSave, loading: isLoading, enabled: isEdited })))),
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col h-full overflow-auto gap-4" },
            react_1.default.createElement("div", { className: "flex flex-col gap-6 h-full" },
                react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Cashback Configuration", textColor: "text-primary", bold: true, className: "bg-blue-50 p-2" }),
                    react_1.default.createElement("div", { className: "grid grid-cols-1 sm:grid-cols-2 gap-4" },
                        react_1.default.createElement("div", { className: "grid grid-cols-1 sm:grid-cols-2 gap-4" },
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Currency", textColor: "text-primary", required: true }),
                                react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "", options: currencyOptions, value: selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.earn_target, required: true, size: "small", borderColor: "border-blue-300", textColor: "text-inputText", errorTextColor: "text-red-500", onChange: function (earn_target) {
                                        onChange({ earn_target: earn_target });
                                    }, errorText: errors === null || errors === void 0 ? void 0 : errors.earn_target })),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Trigger Value", textColor: "text-primary", required: true }),
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: String(selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.trigger_value), onChange: function (trigger_value) {
                                        onChange({ trigger_value: Number(trigger_value) });
                                    }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", type: "number", errorText: errors === null || errors === void 0 ? void 0 : errors.trigger_value })),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Earn Amount", textColor: "text-primary", required: true }),
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: String(selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.earn_amount), onChange: function (earn_amount) {
                                        onChange({ earn_amount: Number(earn_amount) });
                                    }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", type: "number", errorText: errors === null || errors === void 0 ? void 0 : errors.earn_amount })),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(inputLabelComponent_1.InputLabelComponent, { label: "Apply To", textColor: "text-primary", required: true }),
                                react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "", options: segmentOptions, value: selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.segment_id, required: true, size: "small", borderColor: "border-blue-300", textColor: "text-inputText", errorTextColor: "text-red-500", onChange: function (segment_id) {
                                        onChange({ segment_id: segment_id });
                                    }, errorText: errors === null || errors === void 0 ? void 0 : errors.segment_id }))),
                        react_1.default.createElement("div", { className: "grid grid-cols-1 sm:grid-cols-2 gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Exclude", cols: "grid-cols-1", options: constants_1.excludeTypeOptions, selectedValue: (_a = selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.exclude_type) !== null && _a !== void 0 ? _a : "product", onChange: function (exclude_type) {
                                    onChange({ exclude_type: exclude_type });
                                }, colors: pages_1.radioButtonColorConfig, errorText: errors === null || errors === void 0 ? void 0 : errors.exclude_type }),
                            selectedEarnConfig.exclude_type === "category" ? (react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Categories", textColor: "text-primary" }),
                                react_1.default.createElement(doshx_controls_web_1.DropdownMultiControl, { options: categoryOptions, renderDisplay: function (category) { return category.label; }, selectedValues: (_b = categoryOptions.filter(function (category) { var _a; return (_a = selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_category_ids) === null || _a === void 0 ? void 0 : _a.includes(category.value); })) !== null && _b !== void 0 ? _b : [], onSelectionChanged: function (categories) {
                                        onChange({ excluded_category_ids: categories.map(function (category) { return category.value; }) });
                                    }, colors: {
                                        labelColor: "text-inputText",
                                        borderColor: "border-blue-300",
                                        iconColor: "text-primary",
                                    }, size: "small" }))) : (react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Products", textColor: "text-primary" }),
                                react_1.default.createElement(doshx_controls_web_1.DropdownMultiControl, { options: productOptions, renderDisplay: function (product) { return product.label; }, selectedValues: (_c = productOptions.filter(function (product) { var _a; return (_a = selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_product_ids) === null || _a === void 0 ? void 0 : _a.includes(product.value); })) !== null && _c !== void 0 ? _c : [], onSelectionChanged: function (_products) {
                                        onChange({ excluded_product_ids: _products.map(function (product) { return product.value; }) });
                                    }, colors: {
                                        labelColor: "text-inputText",
                                        borderColor: "border-blue-300",
                                        iconColor: "text-primary",
                                    }, size: "small" })))))),
                react_1.default.createElement("div", { className: "grow flex-col gap-2 h-full" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Excluded Product List", textColor: "text-primary", bold: true, className: "bg-blue-50 p-2" }),
                    react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: (0, constants_1.excludeProductsTableColumns)(function (product) {
                            onChange({ excluded_product_ids: selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_product_ids.filter(function (p) { return p !== product._id; }) });
                        }), data: excludedProducts, hasShadow: true, colors: pages_1.tableColorConfig })),
                react_1.default.createElement("div", { className: "grow flex-col gap-2 h-full" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Excluded Category List", textColor: "text-primary", bold: true, className: "bg-blue-50 p-2" }),
                    react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: (0, constants_1.excludeCategoriesTableColumns)(function (category) {
                            onChange({ excluded_category_ids: selectedEarnConfig === null || selectedEarnConfig === void 0 ? void 0 : selectedEarnConfig.excluded_category_ids.filter(function (p) { return p !== category._id; }) });
                        }), data: excludedCategories, hasShadow: true, colors: pages_1.tableColorConfig }))))));
};
exports.EarnConfigCurrencyPage = EarnConfigCurrencyPage;

"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setErrors = exports.setPagination = exports.setIsEdited = exports.setIsLoading = exports.setTierMilestone = exports.setLevelMilestone = exports.setMilestone = exports.setRewardTracks = exports.clearState = exports.setSelectedTierRewardTrack = exports.setSelectedLevelRewardTrack = exports.setSelectedRewardTrack = exports.initialState = exports.initialMilestones = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
exports.initialMilestones = [
    {
        index: 0,
        name: "",
        source_value: "0",
        target_value: "0",
        image_url: "",
        colour: "",
        file: null,
        isFileEdited: false,
    },
];
var initialRewardTrack = {
    name: "",
    start_date: null,
    end_date: null,
    reset_points_annually: false,
    persist_tier_levels_annually: false,
    milestone_count: 1,
    source: "",
    target: "",
    reward_track_type: undefined,
    milestones: exports.initialMilestones,
};
exports.initialState = {
    selectedRewardTrack: initialRewardTrack,
    selectedTierRewardTrack: initialRewardTrack,
    selectedLevelRewardTrack: initialRewardTrack,
    rewardTracks: [],
    pagination: pages_1.initialPaginationValues,
    isLoading: false,
    isEdited: false,
    errors: {
        title: "",
        event_type: "",
        scope: {},
        trigger: {},
        schedule: {},
        rewards: [],
    },
};
var slice = (0, toolkit_1.createSlice)({
    name: "rewardTrackState",
    initialState: exports.initialState,
    reducers: {
        setSelectedRewardTrack: function (state, action) {
            var _a, _b, _c;
            state.selectedRewardTrack = action.payload;
            if (!((_a = state.selectedRewardTrack) === null || _a === void 0 ? void 0 : _a.milestones) || ((_c = (_b = state.selectedRewardTrack) === null || _b === void 0 ? void 0 : _b.milestones) === null || _c === void 0 ? void 0 : _c.length) === 0) {
                state.selectedRewardTrack.milestones = exports.initialMilestones;
            }
        },
        setSelectedTierRewardTrack: function (state, action) {
            var _a, _b, _c;
            state.selectedTierRewardTrack = action.payload;
            if (!((_a = state.selectedTierRewardTrack) === null || _a === void 0 ? void 0 : _a.milestones) || ((_c = (_b = state.selectedTierRewardTrack) === null || _b === void 0 ? void 0 : _b.milestones) === null || _c === void 0 ? void 0 : _c.length) === 0) {
                state.selectedTierRewardTrack.milestones = exports.initialMilestones;
            }
        },
        setSelectedLevelRewardTrack: function (state, action) {
            var _a, _b, _c;
            state.selectedLevelRewardTrack = action.payload;
            if (!((_a = state.selectedLevelRewardTrack) === null || _a === void 0 ? void 0 : _a.milestones) || ((_c = (_b = state.selectedLevelRewardTrack) === null || _b === void 0 ? void 0 : _b.milestones) === null || _c === void 0 ? void 0 : _c.length) === 0) {
                state.selectedLevelRewardTrack.milestones = exports.initialMilestones;
            }
        },
        clearState: function (state) {
            state.selectedRewardTrack = exports.initialState.selectedRewardTrack;
            state.selectedTierRewardTrack = exports.initialState.selectedTierRewardTrack;
            state.selectedLevelRewardTrack = exports.initialState.selectedLevelRewardTrack;
            state.rewardTracks = exports.initialState.rewardTracks;
            state.pagination = exports.initialState.pagination;
            state.isLoading = exports.initialState.isLoading;
            state.isEdited = exports.initialState.isEdited;
            state.errors = exports.initialState.errors;
        },
        setRewardTracks: function (state, action) {
            state.rewardTracks = action.payload;
        },
        setMilestone: function (state, action) {
            state.selectedRewardTrack.milestones[action.payload.index] = action.payload;
        },
        setTierMilestone: function (state, action) {
            state.selectedTierRewardTrack.milestones[action.payload.index] = action.payload;
        },
        setLevelMilestone: function (state, action) {
            state.selectedLevelRewardTrack.milestones[action.payload.index] = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setIsEdited: function (state, action) {
            state.isEdited = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setErrors: function (state, action) {
            state.errors = action.payload;
        },
    },
});
exports.setSelectedRewardTrack = (_a = slice.actions, _a.setSelectedRewardTrack), exports.setSelectedLevelRewardTrack = _a.setSelectedLevelRewardTrack, exports.setSelectedTierRewardTrack = _a.setSelectedTierRewardTrack, exports.clearState = _a.clearState, exports.setRewardTracks = _a.setRewardTracks, exports.setMilestone = _a.setMilestone, exports.setLevelMilestone = _a.setLevelMilestone, exports.setTierMilestone = _a.setTierMilestone, exports.setIsLoading = _a.setIsLoading, exports.setIsEdited = _a.setIsEdited, exports.setPagination = _a.setPagination, exports.setErrors = _a.setErrors;
exports.default = slice.reducer;
